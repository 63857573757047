import { Container, ModalRenderer } from '@dabapps/roe';
import React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';

import FooterBar from '^/app/footer-bar/footer-bar';
import NavBar from '^/app/navigation/nav-bar';
import SideBar from '^/app/navigation/side-bar';
import Tasks from '^/app/tasks';
import TimeEntries from '^/app/time-entries';
import { StoreState } from '^/store/types';

const Placeholder = () => (
  <Container fluid>
    <p>I am a placeholder</p>
  </Container>
);

export type StateProps = Pick<StoreState, 'modals'>;

export type Props = RouteComponentProps<undefined> & StateProps;

const AuthenticatedRoutes = (props: Props) => (
  <>
    <SideBar />
    <NavBar />
    <ModalRenderer modals={props.modals} />
    <Switch>
      <Route path="/placeholder" component={Placeholder} />
      <Route path="/time-entries" component={TimeEntries} />
      <Route path="/" component={Tasks} />
    </Switch>
    <FooterBar />
  </>
);

export const mapStateToProps = ({ modals }: StoreState): StateProps => ({
  modals,
});

export default withRouter(connect(mapStateToProps)(AuthenticatedRoutes));
