export const DATE_FORMAT_ISO = 'YYYY-MM-DD';
export const DATE_FORMAT_TIME_ENTRY_DISPLAY = 'dddd Do MMM';
export const DATE_FORMAT_PICKER_DISPLAY = 'dd MMM yyyy';
export const DATE_FORMAT_QUICK_ADD_DISPLAY = 'ddd MMM Do';

export const NBSP = '\u00a0';

export const DEFAULT_WEEKLY_AVAILABLE_HOURS = 37;

export enum FORM_NAMES {
  SEARCH_FORM_NAME = 'search-tasks',
  TASK_FILTERS_FORM_NAME = 'task-filters',
  RESET_PASSWORD = 'reset-password',
  RESET_PASSWORD_CONFIRMATION = 'reset-password-confirmation',
  SPENT_LEFT = 'spent-left-modal',
  QUICK_ADD = 'quick-add',
}

import { EstimateStatusMap } from './types';

export const ESTIMATE_STATUS_MAP: EstimateStatusMap = {
  NO_ESTIMATE: {
    className: 'no-estimate',
    description: 'No estimate or time entries',
    badgeClassName: '',
    badgeLabel: 'No estimate',
  },
  UNDER_ESTIMATE: {
    className: 'under-estimate',
    description: 'Taking just the right amount of time',
    badgeClassName: 'success',
    badgeLabel: 'On track',
  },
  OVER_ESTIMATE: {
    className: 'over-estimate',
    description: 'Already taking longer than expected',
    badgeClassName: 'error',
    badgeLabel: 'Over budget',
  },
  DANGER_ESTIMATE: {
    className: 'danger-estimate',
    description: 'Probably going to take longer than expected',
    badgeClassName: 'warning',
    badgeLabel: 'Warning',
  },
};
