import { Column, Container, Row } from '@dabapps/roe';
import React from 'react';

export default function LoginWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Container fluid className="login">
      <Row>
        <Column className="panel left">
          <h1>Banjo</h1>
          <img src="/static/img/banjofish.svg" className="login-logo" />
        </Column>
        {children}
      </Row>
    </Container>
  );
}
