import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import Login from '^/app/auth/login';
import ResetPassword from '^/app/auth/reset-password';
import ResetPasswordConfirmation from '^/app/auth/reset-password-confirmation';

const UnauthenticatedRoutes = () => (
  <Switch>
    <Route
      path="/reset-password/:uid/:token/"
      component={ResetPasswordConfirmation}
    />
    <Route path="/reset-password/" component={ResetPassword} />
    <Route exact path="/" component={Login} />
    <Redirect to="/" />
  </Switch>
);

export default UnauthenticatedRoutes;
