import React from 'react';
import DatePicker from 'react-datepicker';
import { WrappedFieldProps } from 'redux-form';

import { DATE_FORMAT_PICKER_DISPLAY } from './constants';

interface OwnProps {
  disabled?: boolean;
  className?: string;
}

export type Props = WrappedFieldProps & OwnProps;

const ReduxDatePicker = (props: Props) => (
  <DatePicker
    selected={props.input.value}
    onChange={props.input.onChange}
    disabled={props.disabled}
    className={props.className}
    dateFormat={DATE_FORMAT_PICKER_DISPLAY}
  />
);

export default ReduxDatePicker;
