import { ChartDataSets } from 'chart.js';
import React from 'react';

import Chart from '^/common/chart';
import { StoreState } from '^/store/types';
import ChartHeaderCard from './chart-header-card';

export interface StateProps extends Pick<StoreState, 'chart'> {
  datasets: ChartDataSets[];
  labels: ReadonlyArray<string>;
}

export type Props = StateProps;

const BanjoChart = ({ chart, labels, datasets }: Props) => (
  <>
    <div className="banjo-chart-header-row estimates-row">
      {chart && (
        <>
          <ChartHeaderCard count={chart.estimate_total} title="Budget" heavy />

          <ChartHeaderCard count={chart.spent_total} title="Hrs spent" />

          <ChartHeaderCard count={chart.left_total} title="Hrs left" />

          <ChartHeaderCard
            count={(
              parseFloat(chart.spent_total) + parseFloat(chart.left_total)
            ).toFixed(2)}
            title="Est. total hrs"
          />
        </>
      )}
    </div>

    <div className="banjo-chart-wrapper">
      {chart && (
        <Chart
          labels={[...labels]}
          datasets={datasets}
          options={{
            legend: {
              display: false,
            },
          }}
        />
      )}
    </div>
  </>
);

export default BanjoChart;
