import { Button, Column, FormGroup, Row } from '@dabapps/roe';
import React from 'react';
import { Link } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { FORM_NAMES } from '^/common/constants';
import FormErrorRenderer from '^/common/error-handling/form-error-renderer';
import { FormErrors } from '^/common/error-handling/types';
import { ResetPasswordConfirmationData } from './types';

interface ExternalProps {
  errors: FormErrors | null;
  loading: boolean;
}

type Props = ExternalProps &
  InjectedFormProps<ResetPasswordConfirmationData, ExternalProps>;

export function ResetPasswordConfirmationForm(props: Props) {
  const { errors, handleSubmit, loading } = props;

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup block>
        <label>New Password</label>
        <Field
          component="input"
          name="new_password1"
          type="password"
          placeholder="New password"
        />
        <FormErrorRenderer formErrorsKey="new_password1" formErrors={errors} />
      </FormGroup>
      <FormGroup block>
        <label>Confirm Password</label>
        <Field
          component="input"
          name="new_password2"
          type="password"
          placeholder="Confirm new password"
        />
        <FormErrorRenderer formErrorsKey="new_password2" formErrors={errors} />
      </FormGroup>
      <Row>
        <Column sm={6}>
          <Button
            type="submit"
            className="button light hollow"
            disabled={loading}
          >
            Reset password
          </Button>
        </Column>
        <Column sm={6} className="text-align-right">
          <Link className="white-link" to="/">
            Back to login
          </Link>
        </Column>
      </Row>
    </form>
  );
}

export default reduxForm<ResetPasswordConfirmationData, ExternalProps>({
  form: FORM_NAMES.RESET_PASSWORD_CONFIRMATION,
})(ResetPasswordConfirmationForm);
