import { Button, Column, FormGroup, Row } from '@dabapps/roe';
import React from 'react';
import { Link } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { FORM_NAMES } from '^/common/constants';
import FormErrorRenderer from '^/common/error-handling/form-error-renderer';
import { FormErrors } from '^/common/error-handling/types';
import { ResetPasswordData } from './actions';

interface ExternalProps {
  errors: FormErrors | null;
  loading: boolean;
}

type Props = ExternalProps &
  InjectedFormProps<ResetPasswordData, ExternalProps>;

function ResetPasswordForm({ errors, handleSubmit, loading }: Props) {
  return (
    <div>
      <p>
        Enter your email address and you will be emailed instructions on how to
        reset your password.
      </p>
      <form onSubmit={handleSubmit}>
        <FormGroup block>
          <Field
            component="input"
            name="email"
            type="email"
            placeholder="Email..."
          />
          <FormErrorRenderer formErrorsKey="email" formErrors={errors} />
        </FormGroup>
        <Row>
          <Column sm={6}>
            <Button type="submit" className="light hollow" disabled={loading}>
              Send reset email
            </Button>
          </Column>
          <Column sm={6} className="text-align-right">
            <Link className="white-link" to="/">
              Back to login
            </Link>
          </Column>
        </Row>
      </form>
    </div>
  );
}

export default reduxForm<ResetPasswordData, ExternalProps>({
  form: FORM_NAMES.RESET_PASSWORD,
})(ResetPasswordForm);
