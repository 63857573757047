import { AnyAction } from 'redux';

import {
  CLOSE_SIDE_BAR,
  OPEN_SIDE_BAR,
} from '^/app/navigation/side-bar/actions';

export const sideBarOpen = (state = false, action: AnyAction) => {
  switch (action.type) {
    case OPEN_SIDE_BAR:
      return true;

    case CLOSE_SIDE_BAR:
      return false;
    default:
      return state;
  }
};
