import { getErrorData } from '@dabapps/redux-requests';
import { Button, Column, FormGroup, Row } from '@dabapps/roe';
import { AxiosError } from 'axios';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { Link } from 'react-router-dom';

import { StoreState } from '^/store/types';
import { GET_CURRENT_USER, LOGIN } from './action-types';
import { login } from './actions';
import LoginWrapper from './login-wrapper';

export interface DispatchProps {
  login: typeof login;
}

export interface StateProps {
  userRequestError: AxiosError | null;
  loginError: AxiosError | null;
}

export type Props = StateProps & ResolveThunks<DispatchProps>;

export interface State {
  username?: string;
  password?: string;
}

export class Login extends React.PureComponent<Props, State> {
  private onChangeUsername = this.onChangeInput.bind(this, 'username');
  private onChangePassword = this.onChangeInput.bind(this, 'password');

  public constructor(props: Props) {
    super(props);

    this.state = {};
  }

  public render() {
    const { loginError, userRequestError } = this.props;

    return (
      <LoginWrapper>
        <Column className="panel right">
          <form onSubmit={this.onLogin}>
            <FormGroup block>
              <input
                value={this.state.username}
                onChange={this.onChangeUsername}
                name="username"
                type="email"
                placeholder="Email..."
              />
            </FormGroup>
            <FormGroup block>
              <input
                value={this.state.password}
                onChange={this.onChangePassword}
                name="password"
                type="password"
                placeholder="Password..."
              />
            </FormGroup>
            <Row>
              <Column sm={6}>
                <Button type="submit" className="light hollow">
                  Login
                </Button>
              </Column>
              <Column sm={6} className="text-align-right">
                <Link className="white-link" to="/reset-password/">
                  Reset password
                </Link>
              </Column>
            </Row>
            {loginError?.response && (
              <p className="error">{loginError.message}</p>
            )}
            {userRequestError?.response && (
              <p className="error">{userRequestError.message}</p>
            )}
          </form>
        </Column>
      </LoginWrapper>
    );
  }

  private onChangeInput(
    key: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    this.setState({
      [key]: event.target.value,
    });
  }

  private onLogin = (event: React.FormEvent) => {
    event.preventDefault();

    const { username, password } = this.state;

    this.props.login({
      username,
      password,
    });
  };
}

export const mapStateToProps = ({ responses }: StoreState): StateProps => {
  return {
    userRequestError: getErrorData(responses, GET_CURRENT_USER),
    loginError: getErrorData(responses, LOGIN),
  };
};

export default connect<StateProps, DispatchProps, {}, StoreState>(
  mapStateToProps,
  { login }
)(Login);
