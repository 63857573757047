import { makeAsyncActionSet, requestWithConfig } from '@dabapps/redux-requests';
import moment from 'moment';
import { ChangeEvent, KeyboardEvent } from 'react';
import { AnyAction, Dispatch } from 'redux';
import { change, formValueSelector } from 'redux-form';

import { UPDATE_TIMES } from '^/app/tasks/action-types';
import { FORM_NAMES } from '^/common/constants';
import { Key, matchesKey } from '^/common/keys';
import { PartialStateThunk } from '^/common/types';
import { toISODate } from '^/common/utils';
import { StoreState } from '^/store/types';
import { fetchTimeEntriesSummary, getTimeEntriesSpent } from '../actions';
import { QuickAddRequestData, SearchTask } from './types';
import { selectSuggestions } from './utils';

const selectQuickAddValue = formValueSelector(FORM_NAMES.QUICK_ADD);

export const quickAddTimeEntry = (): PartialStateThunk<
  'form' | 'currentUser',
  'form'
> => (dispatch, getState) => {
  const state = getState();
  const selectedTask: SearchTask = selectQuickAddValue(state, 'selectedTask');

  const data: QuickAddRequestData = {
    left: {
      value: selectQuickAddValue(state, 'time_left'),
    },
    spent: {
      value: selectQuickAddValue(state, 'time_spent'),
    },
    comment: selectQuickAddValue(state, 'comment'),
    date: toISODate(selectQuickAddValue(state, 'date'), true),
  };

  return dispatch(
    requestWithConfig(
      UPDATE_TIMES,
      {
        method: 'POST',
        url: `/api/tasks/${selectedTask.id}/add-time-entry/`,
        data,
      },
      {
        shouldRethrow: () => true,
      }
    )
  )
    .then(() => {
      dispatch(change(FORM_NAMES.QUICK_ADD, 'selectedTask', undefined));
      dispatch(change(FORM_NAMES.QUICK_ADD, 'search', undefined));
      dispatch(change(FORM_NAMES.QUICK_ADD, 'time_spent', undefined));
      dispatch(change(FORM_NAMES.QUICK_ADD, 'time_left', undefined));
      dispatch(change(FORM_NAMES.QUICK_ADD, 'comment', undefined));

      dispatch(fetchTimeEntriesSummary());
      dispatch(getTimeEntriesSpent(1));
    })
    .catch(() => null);
};

export const GET_RECENT_TASKS = makeAsyncActionSet('GET_RECENT_TASKS');
export const getRecentTasks = (): PartialStateThunk<'form'> => dispatch => {
  return dispatch(
    requestWithConfig(GET_RECENT_TASKS, {
      method: 'GET',
      url: '/api/tasks/recent/',
      data: {
        page_size: 10,
      },
    })
  );
};

const SEARCH_TASKS_CACHE_TIMEOUT = 5 * 60 * 1000;
export const SEARCH_TASKS = makeAsyncActionSet('SEARCH_TASKS');
export const searchTasks = (
  search: string
): PartialStateThunk<'form', 'searchTasks'> => (dispatch, getState) => {
  const time = Date.now();
  const { cache } = getState().searchTasks;

  if (
    !cache[search] ||
    cache[search].time < time - SEARCH_TASKS_CACHE_TIMEOUT
  ) {
    return dispatch(
      requestWithConfig(
        SEARCH_TASKS,
        {
          method: 'GET',
          url: '/api/tasks/search/',
          data: {
            search,
            page_size: 10,
          },
        },
        undefined,
        {
          time,
          search,
        }
      )
    );
  }
};

const setDateNDaysAgo = (days: number) => (
  dispatch: Dispatch<AnyAction, StoreState>
) => {
  const nDaysAgo = moment()
    .subtract(days, 'days')
    .startOf('day')
    .toDate();
  dispatch(change(FORM_NAMES.QUICK_ADD, 'date', nDaysAgo));
};

export const setDateToday = () => setDateNDaysAgo(0);
export const setDateYesterday = () => setDateNDaysAgo(1);
export const setDateTwoDaysAgo = () => setDateNDaysAgo(2);

export const setDateCustom = (
  date: Date
): PartialStateThunk<'form'> => dispatch =>
  dispatch(change(FORM_NAMES.QUICK_ADD, 'date', date));

export const QUICK_ADD_TIME_SPENT_ID = 'quick-add-time-spent-input';
export const selectTaskAndFocusTimeSpent = (
  task: SearchTask
): PartialStateThunk<'form'> => dispatch => {
  dispatch(change(FORM_NAMES.QUICK_ADD, 'selectedTask', task));
  const timeSpentInput = document.getElementById(QUICK_ADD_TIME_SPENT_ID);
  if (timeSpentInput) {
    timeSpentInput.focus();
  }
};

export const OPEN_AUTO_COMPLETE = 'OPEN_AUTO_COMPLETE';
export const CLOSE_AUTO_COMPLETE = 'CLOSE_AUTO_COMPLETE';
export const openAutoComplete = () => ({ type: OPEN_AUTO_COMPLETE });
export const closeAutoComplete = () => ({ type: CLOSE_AUTO_COMPLETE });

export const SET_AUTO_COMPLETE_HIGHLIGHTED_INDEX =
  'SET_AUTO_COMPLETE_HIGHLIGHTED_INDEX';
export const setAutoCompleteHighlightedIndex = (index: number) => ({
  type: SET_AUTO_COMPLETE_HIGHLIGHTED_INDEX,
  payload: index,
});
export const clearAutoCompleteHighlightedIndex = () =>
  setAutoCompleteHighlightedIndex(-1);

export const autoCompleteInputChange = (
  event: ChangeEvent<HTMLInputElement>
): PartialStateThunk<'form' | 'searchTasks'> => dispatch => {
  dispatch(change(FORM_NAMES.QUICK_ADD, 'search', event.currentTarget.value));
  dispatch(setAutoCompleteHighlightedIndex(0));

  if (event.target.value) {
    dispatch(searchTasks(event.target.value));
  }
};

export const autoCompleteKeyDown = (
  event: KeyboardEvent<HTMLInputElement>
): PartialStateThunk<
  'form' | 'recentTasks' | 'searchTasks' | 'autoComplete'
> => (dispatch, getState) => {
  const state = getState();
  const { highlightedIndex } = state.autoComplete;
  const suggestions = selectSuggestions(state);

  if (matchesKey(event, Key.UP_ARROW)) {
    event.preventDefault();

    const newIndex = highlightedIndex - 1;

    return dispatch(
      setAutoCompleteHighlightedIndex(
        newIndex < 0 ? suggestions.length - 1 : newIndex
      )
    );
  } else if (matchesKey(event, Key.DOWN_ARROW)) {
    event.preventDefault();

    return dispatch(
      setAutoCompleteHighlightedIndex(
        (highlightedIndex + 1) % (suggestions.length || 0)
      )
    );
  } else if (matchesKey(event, Key.ESCAPE)) {
    event.currentTarget.blur();
  } else if (matchesKey(event, Key.ENTER)) {
    event.preventDefault();

    if (highlightedIndex >= 0 && suggestions[highlightedIndex]) {
      dispatch(selectTaskAndFocusTimeSpent(suggestions[highlightedIndex]));
    }
  }
};

export const GET_TASK = makeAsyncActionSet('GET_TASK');
export const setSelectedTaskAndClearOtherFields = (
  taskId: string
): PartialStateThunk<'form'> => dispatch => {
  return dispatch(
    requestWithConfig(
      GET_TASK,
      {
        method: 'GET',
        url: `/api/tasks/${taskId}/`,
      },
      { shouldRethrow: () => true }
    )
  ).then(response => {
    if (response) {
      dispatch(selectTaskAndFocusTimeSpent(response.data));
      dispatch(change(FORM_NAMES.QUICK_ADD, 'search', undefined));
      dispatch(change(FORM_NAMES.QUICK_ADD, 'time_spent', undefined));
      dispatch(change(FORM_NAMES.QUICK_ADD, 'time_left', undefined));
      dispatch(change(FORM_NAMES.QUICK_ADD, 'comment', undefined));
    }
  });
};
