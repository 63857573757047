export const required = (value: unknown) =>
  Boolean(value) || value === 0 ? undefined : 'Required';

export const minZero = (value: unknown) => {
  if (!(typeof value === 'string' || typeof value === 'number')) {
    return 'Value must be zero or higher';
  }

  const numValue = typeof value === 'string' ? parseFloat(value) : value;

  return Number.isFinite(numValue) && numValue >= 0
    ? undefined
    : 'Value must be zero or higher';
};
