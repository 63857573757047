import React from 'react';

import { CLOSE_MODAL, OPEN_MODAL } from '^/common/modals/action-types';

export const openModal = (element: React.ReactChild) => ({
  type: OPEN_MODAL,
  payload: element,
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});
