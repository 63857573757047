import { Button, InputGroup } from '@dabapps/roe';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
  submit,
} from 'redux-form';

import { BackendBoolean, SearchData } from '^/common/types';
import { StoreState } from '^/store/types';
import { FORM_NAMES } from '../constants';

export interface ExternalProps extends RouteComponentProps<{}> {
  placeholder: string;
  searchTasks(data: Partial<SearchData>): void;
}

export interface DispatchProps {
  submit: typeof submit;
}

export interface StateProps {
  hasSearchValue: boolean;
  client: string;
  project: string;
  task_group: string;
  assignee: string;
  is_unactionable: BackendBoolean;
}

type Props = DispatchProps &
  StateProps &
  ExternalProps &
  InjectedFormProps<SearchData, StateProps & ExternalProps & DispatchProps>;

export class SearchForm extends React.PureComponent<Props> {
  public render() {
    const { handleSubmit, placeholder, hasSearchValue } = this.props;

    return (
      <form className="search-form" onSubmit={handleSubmit}>
        <InputGroup block className="margin-none">
          <label
            className={classnames('search-form-label', {
              clearable: hasSearchValue,
            })}
          >
            <Button className="search-button" onClick={this.submitForm}>
              <FontAwesomeIcon icon={faSearch} size="1x" />
            </Button>

            <Field
              component="input"
              type="text"
              name="query"
              placeholder={placeholder}
            />

            {hasSearchValue && (
              <Button
                type="button"
                className="clear-search-button"
                onClick={this.clearSearchForm}
              >
                <FontAwesomeIcon icon={faTimes} size="lg" />
              </Button>
            )}
          </label>
        </InputGroup>
      </form>
    );
  }

  private submitForm = () => {
    this.props.submit(this.props.form);
  };

  private clearSearchForm = () => {
    this.props.reset();
    this.props.searchTasks({});
  };
}

const Formified = reduxForm<
  SearchData,
  StateProps & ExternalProps & DispatchProps
>({
  enableReinitialize: true,
})(SearchForm);

const filterValueSelector = formValueSelector(
  FORM_NAMES.TASK_FILTERS_FORM_NAME
);

export const mapStateToProps = (state: StoreState): StateProps => ({
  hasSearchValue: Boolean(
    formValueSelector(FORM_NAMES.SEARCH_FORM_NAME)(state, 'query')
  ),
  client: filterValueSelector(state, 'client'),
  project: filterValueSelector(state, 'project'),
  task_group: filterValueSelector(state, 'task_group'),
  assignee: filterValueSelector(state, 'assignee'),
  is_unactionable: filterValueSelector(state, 'is_unactionable'),
});

export default withRouter(
  connect<StateProps, DispatchProps, ExternalProps, StoreState>(
    mapStateToProps,
    { submit }
  )(Formified)
);
