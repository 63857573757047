import React from 'react';
import { connect } from 'react-redux';

import { closeModal } from '^/common/modals/actions';
import { ESCAPE_KEY_CODE } from '^/common/utils';

interface DispatchProps {
  closeModal: typeof closeModal;
}

const KeyPressWrapper = <OwnProps extends {}>(
  WrappedComponent: React.ComponentType<OwnProps & DispatchProps>
) => {
  class KeyPressWrapperHOC extends React.Component<OwnProps & DispatchProps> {
    public componentDidMount() {
      document.addEventListener('keydown', this.handleKeyPress);
    }

    public componentWillUnmount() {
      document.removeEventListener('keydown', this.handleKeyPress);
    }

    public render() {
      return <WrappedComponent {...this.props} />;
    }

    private handleKeyPress = (event: KeyboardEvent) => {
      if (event.keyCode === ESCAPE_KEY_CODE) {
        this.props.closeModal();
      }
    };
  }

  return connect<{}, DispatchProps, OwnProps>(null, { closeModal })(
    // FIXME: Sorry, I don't really have time to figure out the types for this right now
    KeyPressWrapperHOC as any
  );
};

export default KeyPressWrapper;
