import { Container } from '@dabapps/roe';
import classNames from 'classnames';
import React, { PureComponent } from 'react';

import ToggleEstimate from './toggle-estimate';

export interface PaginationDisplayProps {
  /**
   * className
   */
  className?: string;
  /**
   * items count per page
   */
  pageSize: number;
  /**
   * current page number
   */
  currentPageNumber: number;
  /**
   * total number of items to display
   */
  itemCount: number;
}

export type Props = PaginationDisplayProps;

export class PaginationDisplay extends PureComponent<Props> {
  public render() {
    const { className, itemCount } = this.props;
    return (
      <Container fluid>
        <p className={classNames('pagination-display float-left', className)}>
          Showing {this.showingLowerCount()}-{this.showingUpperCount()} of{' '}
          {itemCount}
        </p>

        <ToggleEstimate />
      </Container>
    );
  }
  private showingLowerCount = () => {
    const { currentPageNumber, pageSize } = this.props;
    return (currentPageNumber - 1) * pageSize || 1;
  };
  private showingUpperCount = () => {
    const { pageSize, currentPageNumber, itemCount } = this.props;
    return pageSize * currentPageNumber > itemCount
      ? itemCount
      : pageSize * currentPageNumber;
  };
}

export default PaginationDisplay;
