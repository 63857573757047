import { Badge, Button, Container, SpacedGroup } from '@dabapps/roe';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { User } from '^/app/auth/types';
import { deleteTimeEntry, updateTimeEntry } from '^/app/time-entries/actions';
import { ESTIMATE_STATUS_MAP } from '^/common/constants';
import ReduxDatePicker from '^/common/redux-date-picker';
import {
  createCallbackOnOnCMDEnterHanlder,
  KeyDownHandler,
} from '^/common/utils';
import { StoreState } from '^/store/types';
import CreatedSystemIcon from '../tasks/created-system-icon';
import { setSelectedTaskAndClearOtherFields } from './quick-add/actions';
import { TimeEntry, TimeEntryFormData } from './types';

export const TIME_ENTRY_DATE_CALL_FORMAT: string = 'YYYY-MM-DD';
export const LAST_N_DAYS: number = 28;
export const PAGE_NUMBER: number = 1;
export const ENTRY_TYPE: string = 'SPENT';

export interface DispatchProps {
  updateTimeEntry: typeof updateTimeEntry;
  deleteTimeEntry: typeof deleteTimeEntry;
  setSelectedTaskAndClearOtherFields: typeof setSelectedTaskAndClearOtherFields;
}

export interface OwnProps {
  entry: TimeEntry;
}

export interface StateProps {
  initialValues: TimeEntryFormData;
  currentUser: User | undefined;
  isUpdating: boolean;
  showEstimateStatusColors: boolean;
}

export interface State {
  editing: boolean;
}

export type ReduxProps = OwnProps & ResolveThunks<DispatchProps> & StateProps;

export type Props = ReduxProps &
  InjectedFormProps<TimeEntryFormData, ReduxProps>;

export class TimeEntryListItem extends React.PureComponent<Props, State> {
  private submitOnCMDEnter: KeyDownHandler<HTMLFormElement>;

  public constructor(props: Props) {
    super(props);

    this.state = {
      editing: false,
    };

    this.submitOnCMDEnter = createCallbackOnOnCMDEnterHanlder(
      props.handleSubmit(this.onSubmitEntry)
    );
  }

  public render() {
    const {
      entry,
      handleSubmit,
      submitting,
      pristine,
      isUpdating,
      showEstimateStatusColors,
    } = this.props;
    const { editing } = this.state;

    const externalId = entry.short_external_id || entry.external_id;
    const badgeLabel =
      ESTIMATE_STATUS_MAP[entry.estimate_status].badgeClassName;

    const addAgainButton = (
      <Button type="button" className="hollow" onClick={this.onClickAddAgain}>
        Add Again
      </Button>
    );

    return (
      <div
        className={classNames(
          'flex-table-bottom-border',
          'padding-vertical-large',
          `${entry.created_system.toLowerCase()}-task-row`,
          { editing },
          {
            [ESTIMATE_STATUS_MAP[entry.estimate_status]
              .className]: showEstimateStatusColors,
          }
        )}
      >
        <Container fluid>
          <form
            className="flex-table-row"
            onSubmit={handleSubmit(this.onSubmitEntry)}
            onKeyDown={this.submitOnCMDEnter}
          >
            <div
              className="flex-table-column flex-fill md-flex-1"
              title={externalId}
            >
              {entry.created_system === 'BANJO' ? (
                <>
                  <CreatedSystemIcon createdSystem={entry.created_system} />
                  <span className="task-id bold">DabApps</span>
                </>
              ) : (
                <a href={entry.url} target="_blank" className="nowrap">
                  <CreatedSystemIcon createdSystem={entry.created_system} />
                  <span className="task-id bold">
                    {entry.created_system === 'DONEDONE'
                      ? `#${externalId}`
                      : externalId}
                  </span>
                </a>
              )}
            </div>
            <div
              className="flex-table-column flex-fill md-flex-1 lg-flex-3 xl-flex-5"
              title={entry.task_name}
            >
              <div>
                <div>{entry.task_name}</div>
                <div className="font-color-light">
                  {entry.client_name}/{entry.project_name} &middot;{' '}
                  {entry.task_group_name}
                  {badgeLabel && showEstimateStatusColors ? (
                    <>
                      {' '}
                      &middot;{' '}
                      <Badge
                        className={
                          ESTIMATE_STATUS_MAP[entry.estimate_status]
                            .badgeClassName
                        }
                        title={
                          ESTIMATE_STATUS_MAP[entry.estimate_status].description
                        }
                      >
                        {ESTIMATE_STATUS_MAP[entry.estimate_status].badgeLabel}
                      </Badge>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              className="flex-table-column flex-fill md-flex-1"
              title={entry.comment}
            >
              {editing ? (
                <Field
                  component="input"
                  name="comment"
                  type="text"
                  className="text-input"
                />
              ) : (
                entry.comment
              )}
            </div>
            <div className="flex-table-column flex-fill md-flex-1">
              {editing ? (
                <Field
                  component="input"
                  name="time"
                  type="number"
                  min="0"
                  step="0.25"
                  className="time-input"
                />
              ) : (
                `${entry.time}h`
              )}
            </div>
            <div className="flex-table-column flex-fill md-flex-1">
              {editing ? (
                <Field
                  component={ReduxDatePicker}
                  name="date"
                  className="date-input"
                />
              ) : (
                moment(entry.date).format('DD MMM YYYY')
              )}
            </div>
            <div className="flex-table-column flex-fill md-flex-2">
              {!editing && (
                <SpacedGroup className="display-block nowrap">
                  <Button
                    type="button"
                    onClick={this.onToggleEditing}
                    className="primary"
                  >
                    Edit
                  </Button>
                  {addAgainButton}
                  <Button
                    type="button"
                    className="delete"
                    onClick={this.onDeleteEntry}
                    disabled={isUpdating}
                  >
                    Delete
                  </Button>
                </SpacedGroup>
              )}
              {editing && (
                <SpacedGroup className="display-flex flex-fill justify-content-stretch">
                  <Button
                    type="button"
                    className="hollow"
                    onClick={this.onCancelEditing}
                  >
                    Cancel
                  </Button>
                  {addAgainButton}
                  <Button
                    type="submit"
                    className="success"
                    disabled={submitting || pristine || isUpdating}
                  >
                    Save
                  </Button>
                </SpacedGroup>
              )}
            </div>
          </form>
        </Container>
      </div>
    );
  }

  private onClickAddAgain = () => {
    this.props.setSelectedTaskAndClearOtherFields(this.props.entry.task);
  };

  private onToggleEditing = (event: React.FormEvent) => {
    const { editing } = this.state;
    event.preventDefault();
    this.setState({ editing: !editing });
  };

  private onCancelEditing = (event: React.FormEvent) => {
    event.preventDefault();
    this.setState({ editing: false });
  };

  private onSubmitEntry = (data: TimeEntryFormData) => {
    this.props.updateTimeEntry({
      date: data.date || null,
      time: data.time || null,
      time_entry_id: this.props.entry.id,
      comment: data.comment,
    });

    this.setState({ editing: false });
  };

  private onDeleteEntry = () => {
    if (window.confirm('Do you want to delete the entry?')) {
      this.props.deleteTimeEntry(this.props.entry.id);
    }
  };
}

export const mapStateToProps = (
  { currentUser, timeEntries, showEstimateStatusColors }: StoreState,
  props: OwnProps
): StateProps => {
  const timeEntry = timeEntries.data?.find(
    entry => entry.id === props.entry.id
  );

  return {
    initialValues: {
      date: timeEntry && moment.utc(timeEntry.date).toDate(),
      time: timeEntry && timeEntry.time,
      comment: timeEntry && timeEntry.comment,
    },
    currentUser: currentUser.data,
    isUpdating: timeEntries.loadingIds.indexOf(props.entry.id) >= 0,
    showEstimateStatusColors,
  };
};

const TimeEntriesForm = reduxForm<TimeEntryFormData, ReduxProps>({
  enableReinitialize: true,
  destroyOnUnmount: false,
  updateUnregisteredFields: true,
})(TimeEntryListItem);

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
  mapStateToProps,
  {
    updateTimeEntry,
    deleteTimeEntry,
    setSelectedTaskAndClearOtherFields,
  }
)(TimeEntriesForm);
