import { requestWithConfig } from '@dabapps/redux-requests';
import { push } from 'connected-react-router';
import { AnyAction, Dispatch } from 'redux';

import { StoreState } from '^/store/types';
import {
  CLEAR_USER,
  GET_CURRENT_USER,
  LOGIN,
  LOGOUT,
  RESET_PASSWORD,
  RESET_PASSWORD_CONFIRMATION,
} from './action-types';
import { ResetPasswordConfirmationData, User } from './types';

export interface UsernamePassword {
  username?: string;
  password?: string;
}

export interface ResetPasswordData {
  email?: string;
}

export const clearUser = () => ({
  type: CLEAR_USER,
});

export const getCurrentUser = () =>
  requestWithConfig(
    GET_CURRENT_USER,
    {
      method: 'GET',
      url: '/api/accounts/me/',
    },
    {
      shouldRethrow: () => true,
    }
  );

export const getCurrentUserWithCatch = () => (
  dispatch: Dispatch<AnyAction, StoreState>
) => dispatch(getCurrentUser()).catch(() => null);

export const login = (data: UsernamePassword) => (
  dispatch: Dispatch<AnyAction, StoreState>
) =>
  dispatch(
    requestWithConfig(
      LOGIN,
      {
        method: 'POST',
        url: '/api/accounts/login/',
        data,
      },
      {
        shouldRethrow: () => true,
      }
    )
  )
    .then(() => {
      return dispatch(getCurrentUser());
    })
    .then(response => {
      const currentUser = response && (response.data as User);

      if (currentUser) {
        dispatch(push(`/?assignee=${currentUser.id}`));
      }
    })
    .catch(() => null);

export const logout = () => (dispatch: Dispatch<AnyAction, StoreState>) =>
  dispatch(
    requestWithConfig(
      LOGOUT,
      {
        method: 'POST',
        url: '/api/accounts/logout/',
      },
      { shouldRethrow: () => true }
    )
  )
    .then(() => {
      dispatch(push('/'));
      dispatch(clearUser());
    })
    .catch(() => null);

export const resetPassword = (data: ResetPasswordData) =>
  requestWithConfig(RESET_PASSWORD, {
    method: 'POST',
    url: '/api/auth/password/reset/',
    data,
  });

export const resetPasswordConfirmation = (
  data: ResetPasswordConfirmationData
) =>
  requestWithConfig(RESET_PASSWORD_CONFIRMATION, {
    method: 'POST',
    url: '/api/auth/password/reset/confirm/',
    data,
  });
