import { Column } from '@dabapps/roe';
import { AxiosError } from 'axios';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { Link } from 'react-router-dom';

import { StoreState } from '^/store/types';
import { resetPassword, ResetPasswordData } from './actions';
import LoginWrapper from './login-wrapper';
import ResetPasswordForm from './reset-password-form';

interface DispatchProps {
  resetPassword: typeof resetPassword;
}

interface StateProps {
  loading: boolean;
  data: {} | undefined;
  error: AxiosError | undefined;
}

type Props = StateProps & ResolveThunks<DispatchProps>;

export class ResetPassword extends React.PureComponent<Props> {
  public render() {
    const { error, data, loading } = this.props;

    return (
      <LoginWrapper>
        <Column className="panel right">
          <ResetPasswordForm
            onSubmit={this.sendResetEmail}
            errors={error?.response?.data}
            loading={loading}
          />
          {data && (
            <>
              <p>
                You have been sent instructions on resetting your password to
                your email address if you have an account registered with us.
              </p>
              <Link className="white-link margin-vertical-base" to="/">
                Back to login
              </Link>
            </>
          )}
        </Column>
      </LoginWrapper>
    );
  }

  private sendResetEmail = (data: ResetPasswordData) => {
    this.props.resetPassword(data);
  };
}

export const mapStateToProps = ({
  resetPasswordState,
}: StoreState): StateProps => {
  return {
    loading: resetPasswordState.isLoading,
    data: resetPasswordState.data,
    error: resetPasswordState.error,
  };
};

export default connect(mapStateToProps, { resetPassword })(ResetPassword);
