export const CLOSE_SIDE_BAR = 'CLOSE_SIDE_BAR';
export const OPEN_SIDE_BAR = 'OPEN_SIDE_BAR';

export const openSideBar = () => ({
  type: OPEN_SIDE_BAR,
});

export const closeSideBar = () => ({
  type: CLOSE_SIDE_BAR,
});
