import moment from 'moment';
import numeral from 'numeral';
import { createSelector } from 'reselect';

import { DATE_FORMAT_ISO } from '^/common/constants';
import { StoreState } from '^/store/types';
import { GroupedEntryData, TimeEntry } from './types';

export const getFormattedTimeEntryDate = (
  date: string,
  format: string = DATE_FORMAT_ISO
): string => {
  return moment(date).format(format);
};

export const getUniqueDates = (
  timeEntries: ReadonlyArray<TimeEntry> | undefined
): string[] => {
  if (timeEntries) {
    return timeEntries.reduce<string[]>((memo, entry) => {
      const date = getFormattedTimeEntryDate(entry.date);

      return !memo.includes(date) ? memo.concat(date) : memo;
    }, []);
  }

  return [];
};

export const getEntriesByDate = (
  timeEntries: ReadonlyArray<TimeEntry> | undefined,
  date: string
): TimeEntry[] => {
  if (timeEntries) {
    return timeEntries
      .filter(entry => getFormattedTimeEntryDate(entry.date) === date)
      .sort((a: TimeEntry, b: TimeEntry) =>
        moment(b.modified).diff(a.modified)
      );
  }

  return [];
};

export const formatTimeToHoursMinutes = (time: string): string => {
  const integer = time.split('.')[0];
  const fraction = Math.round((Number(time.split('.')[1]) * 60) / 100);
  return `${integer}h ${fraction > 0 ? fraction : ''}${
    fraction > 0 ? 'm' : ''
  }`;
};

export const getTotalTime = (
  timeEntries: ReadonlyArray<TimeEntry> | undefined,
  date: string
): string => {
  return getEntriesByDate(timeEntries, date)
    .reduce((memo, entry) => memo.add(entry.time), numeral(0))
    .format('0.00');
};

export const getTotalTimeCount = (
  timeEntries: ReadonlyArray<TimeEntry> | undefined,
  date: string
): string => formatTimeToHoursMinutes(getTotalTime(timeEntries, date));

export const getGroupedEntriesList = (
  timeEntries: ReadonlyArray<TimeEntry> | undefined
): GroupedEntryData[] => {
  if (timeEntries) {
    return getUniqueDates(timeEntries)
      .sort()
      .reverse()
      .map<GroupedEntryData>(date => ({
        itemDate: date,
        rawTotalCount: parseFloat(getTotalTime(timeEntries, date)),
        totalTimeCount: getTotalTimeCount(timeEntries, date),
        items: getEntriesByDate(timeEntries, date),
      }));
  }

  return [];
};

export const selectGroupedTimeEntries = createSelector(
  ({ timeEntries }: Pick<StoreState, 'timeEntries'>) => timeEntries.data,
  timeEntries => getGroupedEntriesList(timeEntries)
);

export const getTimeEntriesSummary = ({
  timeEntriesSummary,
}: Pick<StoreState, 'timeEntriesSummary'>) => timeEntriesSummary.data;
