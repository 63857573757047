import { isPending } from '@dabapps/redux-requests/dist/js';
import { Button } from '@dabapps/roe';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { createSelector } from 'reselect';

import {
  GET_ASSIGNEES,
  GET_CLIENTS,
  GET_PROJECTS,
  GET_TASK_GROUPS,
  GET_TASKS,
  getAssignees,
} from '^/app/tasks/actions';
import { Filter } from '^/app/tasks/filter';
import { StatusFilter } from '^/app/tasks/status-filter';
import {
  Assignee,
  FilterOption,
  GenericObject,
  TaskFiltersObject,
} from '^/app/tasks/types';
import { FORM_NAMES } from '^/common/constants';
import { buildCleanFilterOptions } from '^/common/utils';
import { StoreState } from '^/store/types';

type Props = StateProps &
  ResolveThunks<DispatchProps> &
  RouteComponentProps<{}> &
  InjectedFormProps<
    TaskFiltersObject,
    StateProps & ResolveThunks<DispatchProps> & RouteComponentProps<{}>
  >;

export interface StateProps {
  clients: ReadonlyArray<FilterOption>;
  projects: ReadonlyArray<FilterOption>;
  taskGroups: ReadonlyArray<FilterOption>;
  assignees: ReadonlyArray<FilterOption>;
  clientsIsLoading: boolean;
  projectsIsLoading: boolean;
  taskGroupsIsLoading: boolean;
  assigneesIsLoading: boolean;
  tasksIsLoading: boolean;
  clientValue: string;
  initialValues: GenericObject;
  hasAnyFilterValue: boolean;
  searchValue: string | undefined;
}

export interface DispatchProps {
  getAssignees: typeof getAssignees;
  push: typeof push;
}

export class TaskFiltersForm extends React.PureComponent<Props> {
  public componentDidMount() {
    this.props.getAssignees();
  }

  public render() {
    const {
      clients,
      projects,
      taskGroups,
      assignees,
      clientsIsLoading,
      projectsIsLoading,
      taskGroupsIsLoading,
      assigneesIsLoading,
      tasksIsLoading,
      clientValue,
      hasAnyFilterValue,
    } = this.props;

    return (
      <form className="task-filters">
        <Filter
          filterOptions={clients}
          name="client"
          label="client"
          loading={clientsIsLoading}
          disabled={tasksIsLoading}
        />
        <Filter
          filterOptions={projects}
          name="project"
          label="project"
          loading={projectsIsLoading}
          disabled={!projects.length || tasksIsLoading}
        />
        <Filter
          filterOptions={taskGroups}
          name="task_group"
          label="task group"
          loading={taskGroupsIsLoading}
          disabled={!clientValue || tasksIsLoading}
        />
        <Filter
          filterOptions={assignees}
          name="assignee"
          label="Assignee"
          loading={assigneesIsLoading}
          disabled={tasksIsLoading}
        />
        <StatusFilter
          name="is_unactionable"
          label="status"
          loading={clientsIsLoading}
          disabled={tasksIsLoading}
        />
        <Button
          disabled={!hasAnyFilterValue}
          className="clear-filter-button"
          onClick={this.onClearFiltersClicked}
        >
          <FontAwesomeIcon icon={faTimes} size="sm" />
          Clear
        </Button>
      </form>
    );
  }

  private onClearFiltersClicked = () => {
    const { searchValue } = this.props;
    const filters = buildCleanFilterOptions({
      page: undefined,
      search: searchValue,
    });
    const newParams = queryString.stringify(filters);
    this.props.reset();
    this.props.push({ search: newParams });
  };
}

const assigneeSelector = createSelector(
  (state: StoreState) => state.assignees,
  (assignees: ReadonlyArray<Assignee> | null) =>
    assignees
      ? assignees.map(({ id, full_name }) => ({ id, name: full_name }))
      : []
);

function getFilterFromQueryString(
  props: RouteComponentProps<{}>,
  field: string
): string {
  const queryStringPage = queryString.parse(props.location.search)[field];
  const filterValue =
    typeof queryStringPage === 'string' ? queryStringPage : '';
  return filterValue;
}

const filterValueSelector = formValueSelector(
  FORM_NAMES.TASK_FILTERS_FORM_NAME
);

export const mapStateToProps = (
  state: StoreState,
  props: RouteComponentProps<{}>
): StateProps => {
  return {
    searchValue: formValueSelector(FORM_NAMES.SEARCH_FORM_NAME)(state, 'query'),
    hasAnyFilterValue:
      Boolean(filterValueSelector(state, 'client')) ||
      Boolean(filterValueSelector(state, 'project')) ||
      Boolean(filterValueSelector(state, 'task_group')) ||
      Boolean(filterValueSelector(state, 'assignee')) ||
      Boolean(filterValueSelector(state, 'is_unactionable')),
    clients: state.clients || [],
    projects: state.projects || [],
    taskGroups: state.taskGroups || [],
    assignees: assigneeSelector(state),
    clientsIsLoading: isPending(state.responses, GET_CLIENTS),
    projectsIsLoading: isPending(state.responses, GET_PROJECTS),
    taskGroupsIsLoading: isPending(state.responses, GET_TASK_GROUPS),
    assigneesIsLoading: isPending(state.responses, GET_ASSIGNEES),
    tasksIsLoading: isPending(state.responses, GET_TASKS),
    clientValue: formValueSelector(FORM_NAMES.TASK_FILTERS_FORM_NAME)(
      state,
      'client'
    ),
    initialValues: {
      client: getFilterFromQueryString(props, 'client'),
      project: getFilterFromQueryString(props, 'project'),
      task_group: getFilterFromQueryString(props, 'task_group'),
      assignee: getFilterFromQueryString(props, 'assignee'),
      is_unactionable: getFilterFromQueryString(props, 'is_unactionable'),
    },
  };
};

const form = reduxForm<
  TaskFiltersObject,
  StateProps & ResolveThunks<DispatchProps> & RouteComponentProps<{}>
>({
  form: FORM_NAMES.TASK_FILTERS_FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(TaskFiltersForm);

export default connect(mapStateToProps, {
  getAssignees,
  push,
})(form);
