import React, { PureComponent } from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';

import { focusRef, preventDefault } from '^/common/utils';
import { StoreState } from '^/store/types';
import {
  autoCompleteInputChange,
  autoCompleteKeyDown,
  clearAutoCompleteHighlightedIndex,
  closeAutoComplete,
  getRecentTasks,
  openAutoComplete,
} from './actions';
import Suggestion from './suggestion';
import { SearchTask } from './types';
import { selectLoadingCurrentSearch, selectSuggestions } from './utils';

interface OwnProps extends WrappedFieldProps {
  placeholder?: string;
}

interface StateProps {
  suggestions: ReadonlyArray<SearchTask>;
  open: boolean;
  highlightedIndex: number;
  loadingCurrentSearch: boolean;
}

interface DispatchProps {
  getRecentTasks: typeof getRecentTasks;
  onInputFocus: typeof openAutoComplete;
  onInputBlur: typeof closeAutoComplete;
  onInputChange: typeof autoCompleteInputChange;
  onInputKeyDown: typeof autoCompleteKeyDown;
  onDropdownMouseOut: typeof clearAutoCompleteHighlightedIndex;
}

export type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

class AutoComplete extends PureComponent<Props> {
  public componentDidMount() {
    this.props.getRecentTasks();
  }

  public render() {
    const {
      input,
      suggestions,
      placeholder,
      open,
      onInputChange,
      onInputFocus,
      onInputBlur,
      onInputKeyDown,
      highlightedIndex,
      onDropdownMouseOut,
      loadingCurrentSearch,
    } = this.props;

    const loadingOrNoResults = loadingCurrentSearch ? (
      <li>Loading...</li>
    ) : (
      <li>No matches</li>
    );

    return (
      <div className="auto-complete">
        <input
          ref={focusRef}
          type="text"
          placeholder={placeholder}
          value={input.value}
          onChange={onInputChange}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          onKeyDown={onInputKeyDown}
        />
        {open && (
          <ul
            className="dropdown"
            onMouseDown={preventDefault}
            onTouchStart={preventDefault}
            onMouseOut={onDropdownMouseOut}
          >
            {Boolean(suggestions.length)
              ? suggestions.map((task, index) => (
                  <Suggestion
                    key={task.id}
                    task={task}
                    highlighted={index === highlightedIndex}
                    index={index}
                  />
                ))
              : loadingOrNoResults}
          </ul>
        )}
      </div>
    );
  }
}

export const mapStateToProps = (
  state: Pick<
    StoreState,
    'timeEntries' | 'form' | 'recentTasks' | 'searchTasks' | 'autoComplete'
  >
): StateProps => {
  return {
    ...state.autoComplete,
    loadingCurrentSearch: selectLoadingCurrentSearch(state),
    suggestions: selectSuggestions(state),
  };
};

export { AutoComplete };

export default connect(mapStateToProps, {
  getRecentTasks,
  onInputFocus: openAutoComplete,
  onInputBlur: closeAutoComplete,
  onInputChange: autoCompleteInputChange,
  onInputKeyDown: autoCompleteKeyDown,
  onDropdownMouseOut: clearAutoCompleteHighlightedIndex,
})(AutoComplete);
