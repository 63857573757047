import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { toggleEstimateStatusColors } from '^/app/tasks/actions';
import { StoreState } from '^/store/types';

export interface DispatchProps {
  toggleEstimateStatusColors: typeof toggleEstimateStatusColors;
}

export type StateProps = Pick<StoreState, 'showEstimateStatusColors'>;

export type Props = StateProps & DispatchProps;

export class ToggleEstimate extends PureComponent<Props> {
  public render() {
    return (
      <p className="float-right">
        <a onClick={this.props.toggleEstimateStatusColors}>
          {this.props.showEstimateStatusColors ? 'Hide' : 'Show'}
          {' Task Status'}
        </a>
      </p>
    );
  }
}

export const mapStateToProps = ({
  showEstimateStatusColors,
}: StoreState): StateProps => ({
  showEstimateStatusColors,
});

export default connect<StateProps, DispatchProps, {}, StoreState>(
  mapStateToProps,
  {
    toggleEstimateStatusColors,
  }
)(ToggleEstimate);
