import React from 'react';

import { CreatedSystems } from './types';

export interface Props {
  createdSystem: CreatedSystems;
}

const getCreatedSystemImage = (createdSystem: CreatedSystems) => {
  switch (createdSystem) {
    case 'DONEDONE':
      return '/static/img/DoneDone@2x.png';
    case 'TRELLO':
      return '/static/img/Trello@2x.png';
    default:
      return '/static/img/DabApps@2x.png';
  }
};

const CreatedSystemIcon = ({ createdSystem }: Props) => (
  <img
    src={getCreatedSystemImage(createdSystem)}
    className="created-system-icon"
  />
);

export default CreatedSystemIcon;
