import classNames from 'classnames';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';

import {
  selectTaskAndFocusTimeSpent,
  setAutoCompleteHighlightedIndex,
} from './actions';
import { SearchTask } from './types';

interface DispatchProps {
  setAutoCompleteHighlightedIndex: typeof setAutoCompleteHighlightedIndex;
  selectTaskAndFocusTimeSpent: typeof selectTaskAndFocusTimeSpent;
}

interface OwnProps {
  task: SearchTask;
  highlighted: boolean;
  index: number;
}

interface MergeProps {
  onMouseOver: () => void;
  onClick: () => void;
}

export type Props = OwnProps & ResolveThunks<DispatchProps> & MergeProps;

const Suggestion = ({ task, highlighted, onMouseOver, onClick }: Props) => (
  <li
    className={classNames({ highlighted })}
    onMouseOver={onMouseOver}
    onClick={onClick}
  >
    <div className="truncate primary">{task.name}</div>
    <div className="truncate">
      {task.client_name} &middot; {task.project_name} &middot;{' '}
      {task.task_group_name}
    </div>
  </li>
);

export const mergeProps = (
  stateProps: {},
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): Props => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onMouseOver: () =>
    dispatchProps.setAutoCompleteHighlightedIndex(ownProps.index),
  onClick: () => dispatchProps.selectTaskAndFocusTimeSpent(ownProps.task),
});

export { Suggestion };

export default connect<{}, DispatchProps, OwnProps, Props>(
  undefined,
  { setAutoCompleteHighlightedIndex, selectTaskAndFocusTimeSpent },
  mergeProps
)(Suggestion);
