import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { getCurrentUserWithCatch } from '^/app/auth/actions';
import Loading from '^/common/loading';
import { StoreState } from '^/store/types';
import { User } from './auth/types';
import AuthenticatedRoutes from './authenticated-routes';
import UnauthenticatedRoutes from './unauthenticated-routes';

type RouteProps = RouteComponentProps<{}>;

export interface DispatchProps {
  getCurrentUserWithCatch: typeof getCurrentUserWithCatch;
}

export interface StateProps {
  currentUser: User | undefined;
  isLoading: boolean;
}

export type Props = RouteProps & StateProps & ResolveThunks<DispatchProps>;

export class App extends React.PureComponent<Props> {
  public componentDidMount() {
    if (this.props.currentUser) {
      this.props.getCurrentUserWithCatch();
    }
  }

  public render() {
    const { currentUser, isLoading } = this.props;

    if (currentUser && isLoading) {
      return <Loading />;
    }

    if (currentUser) {
      return <AuthenticatedRoutes />;
    }

    return <UnauthenticatedRoutes />;
  }
}

export const mapStateToProps = ({ currentUser }: StoreState): StateProps => {
  return {
    currentUser: currentUser.data,
    isLoading: currentUser.isLoading,
  };
};

export default withRouter(
  connect<StateProps, DispatchProps, RouteProps, StoreState>(mapStateToProps, {
    getCurrentUserWithCatch,
  })(App)
);
