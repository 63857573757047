import {
  Banner,
  Button,
  Column,
  Container,
  Row,
  SpacedGroup,
} from '@dabapps/roe';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { closeFooterBar } from '^/app/footer-bar/actions';
import { StoreState } from '^/store/types';

export interface DispatchProps {
  closeFooterBar: typeof closeFooterBar;
}

export type StateProps = Pick<StoreState, 'footerBarState'>;

export type OwnProps = RouteComponentProps<undefined>;

export type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

export class FooterBar extends React.PureComponent<Props> {
  public render() {
    const { footerBarState } = this.props;
    const isError = footerBarState.msg;

    return (
      <Container fluid>
        {footerBarState.footerBarOpen && (
          <Banner
            open
            position="bottom"
            className={isError ? 'error' : 'success'}
          >
            <Container>
              <Row>
                <Column xs={10}>
                  {isError ? (
                    <p>
                      Operation Failed: {footerBarState.msg}
                      <br />
                      {this._displayErrors(footerBarState.err)}
                    </p>
                  ) : (
                    <SpacedGroup>
                      <p className="display-inline-block">
                        Operation successful!
                      </p>
                      <FontAwesomeIcon
                        className="banjo-burndown-icon"
                        icon={faCheck}
                      />
                    </SpacedGroup>
                  )}
                </Column>
                <Column xs={2}>
                  {isError && (
                    <Button
                      className="margin-top-base float-right"
                      onClick={this.onDismiss}
                    >
                      Dismiss
                    </Button>
                  )}
                </Column>
              </Row>
            </Container>
          </Banner>
        )}
      </Container>
    );
  }

  private _displayErrors(err: ReadonlyArray<string> | null) {
    return err ? (
      <div>
        {err.map(item => (
          <div key={item}>{item}</div>
        ))}
      </div>
    ) : null;
  }

  private onDismiss = () => {
    this.props.closeFooterBar();
  };
}

export const mapStateToProps = (
  { footerBarState }: StoreState,
  props: OwnProps
): StateProps & OwnProps => {
  return {
    ...props,
    footerBarState,
  };
};

export default withRouter(
  connect<StateProps, DispatchProps, OwnProps, StoreState>(mapStateToProps, {
    closeFooterBar,
  })(FooterBar)
);
