import {
  makeAsyncActionSet,
  request,
  requestWithConfig,
} from '@dabapps/redux-requests';
import { AnyAction, Dispatch } from 'redux';

import { PartialStateThunk } from '^/common/types';
import { toISODate } from '^/common/utils';
import { StoreState } from '^/store/types';
import { SubmitEntryData } from './types';

export const GET_TIME_ENTRIES_SPENT = makeAsyncActionSet(
  'GET_TIME_ENTRIES_SPENT'
);

export const GET_TIME_ENTRIES_FOR_PAGE = makeAsyncActionSet(
  'GET_TIME_ENTRIES_FOR_PAGE'
);

export const getTimeEntriesSpent = (
  pageNumber: number
): PartialStateThunk<'currentUser'> => (dispatch, getState) => {
  const user = getState().currentUser.data;
  return dispatch(
    request(GET_TIME_ENTRIES_SPENT, '/api/tasks/time-entries/spent/', 'GET', {
      user: user && user.id,
      page: pageNumber,
    })
  );
};

export const getTimeEntriesForPage = (
  pageNumber: number
): PartialStateThunk<'timeEntries' | 'currentUser'> => (dispatch, getState) => {
  const user = getState().currentUser.data;
  return dispatch(
    request(
      GET_TIME_ENTRIES_FOR_PAGE,
      '/api/tasks/time-entries/spent/',
      'GET',
      {
        user: user && user.id,
        page: pageNumber,
      }
    )
  );
};

export const FETCH_TIME_ENTRIES_SUMMARY = makeAsyncActionSet(
  'GET_TIME_ENTRIES_SPENT_SUMMARY'
);

export const fetchTimeEntriesSummary = () =>
  request(
    FETCH_TIME_ENTRIES_SUMMARY,
    '/api/tasks/time-entries/summary/',
    'GET'
  );

export const DELETE_TIME_ENTRY = makeAsyncActionSet('DELETE_TIME_ENTRY');

export const deleteTimeEntry = (id: string) => (
  dispatch: Dispatch<AnyAction, StoreState>
) =>
  dispatch(
    requestWithConfig(
      DELETE_TIME_ENTRY,
      {
        method: 'DELETE',
        url: `/api/tasks/time-entries/${id}/`,
      },
      {
        shouldRethrow: () => true,
      },
      {
        id,
      }
    )
  )
    .then(() => {
      dispatch(fetchTimeEntriesSummary());
    })
    .catch(() => null);

export const UPDATE_TIME_ENTRY = makeAsyncActionSet('UPDATE_TIME_ENTRY');

export const returnTrue = () => true;

export const updateTimeEntry = (data: SubmitEntryData) => (
  dispatch: Dispatch<AnyAction, StoreState>
) =>
  dispatch(
    requestWithConfig(
      UPDATE_TIME_ENTRY,
      {
        method: 'PATCH',
        url: `/api/tasks/time-entries/${data.time_entry_id}/`,
        data: {
          ...data,
          ...(data.date && { date: toISODate(data.date) }),
        },
      },
      {
        shouldRethrow: returnTrue,
      },
      {
        id: data.time_entry_id,
      }
    )
  )
    .then(() => {
      dispatch(fetchTimeEntriesSummary());
    })
    .catch(() => null);
