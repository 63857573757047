import React from 'react';

import { FormErrors } from '^/common/error-handling/types';

interface ExternalProps {
  formErrors: FormErrors | null;
  formErrorsKey: string;
}

type Props = ExternalProps;

export default class FormErrorRenderer extends React.PureComponent<Props> {
  public render() {
    const { formErrors, formErrorsKey } = this.props;

    if (!formErrors || !Object.keys(formErrors).length) {
      return null;
    }

    const error = formErrors[formErrorsKey];

    if (Array.isArray(error)) {
      return (
        <ul className="error-list">
          {error.map((subError, i) => (
            <li className="error" key={`${subError}-${i}`}>
              {subError}
            </li>
          ))}
        </ul>
      );
    }

    return null;

    // FIXME: Handle nested response errors
  }
}
