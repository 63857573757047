import React from 'react';
import { Field } from 'redux-form';

export interface OwnProps {
  name: string;
  label: string;
  loading: boolean;
  disabled?: boolean;
}

export type Props = OwnProps;

export class StatusFilter extends React.PureComponent<Props> {
  public render() {
    const { name, label, loading, disabled } = this.props;
    return (
      <Field
        name={name}
        id={name}
        component="select"
        disabled={loading || disabled}
      >
        {!loading && <option value="">All {label}es</option>}
        {loading ? (
          <option>loading...</option>
        ) : (
          <>
            <option value="False">Open</option>
            <option value="True">Unactionable</option>
          </>
        )}
      </Field>
    );
  }
}
