import { createReducer, Handlers } from '@dabapps/redux-create-reducer';
import { AsyncActionSet } from '@dabapps/redux-requests';
import { AxiosError, AxiosResponse } from 'axios';

import { ReduxRequestsAction } from './types';

export interface SimpleRequestState<T> {
  isLoading: boolean;
  inFlightCount: number;
  data: T | undefined;
  error: AxiosError | undefined;
}

export const createSimpleRequestReducer = <T, R = T, AdditionalState = {}>(
  actionSet: AsyncActionSet,
  additionalInitialState: AdditionalState,
  extractData: (response: AxiosResponse<R>) => T | undefined = (
    response: AxiosResponse<any>
  ) => response.data,
  additionalHandlers?: Handlers<
    SimpleRequestState<T> & AdditionalState,
    ReduxRequestsAction<AxiosResponse<any>, any>
  >
) => {
  return createReducer<
    SimpleRequestState<T> & AdditionalState,
    ReduxRequestsAction<AxiosResponse<R>>
  >(
    {
      [actionSet.REQUEST]: state => ({
        ...state,
        inFlightCount: state.inFlightCount + 1,
        isLoading: true,
      }),
      [actionSet.SUCCESS]: (state, action) => ({
        ...state,
        inFlightCount: Math.max(state.inFlightCount - 1, 0),
        isLoading: state.inFlightCount - 1 > 0,
        data: extractData(action.payload),
        error: undefined,
      }),
      [actionSet.FAILURE]: (state, action) => ({
        ...state,
        inFlightCount: Math.max(state.inFlightCount - 1, 0),
        isLoading: state.inFlightCount - 1 > 0,
        error: action.payload,
      }),
      ...additionalHandlers,
    },
    {
      isLoading: false,
      inFlightCount: 0,
      data: undefined,
      error: undefined,
      ...additionalInitialState,
    }
  );
};
