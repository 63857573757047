import React from 'react';
import { AnyAction } from 'redux';

import { CLOSE_MODAL, OPEN_MODAL } from '^/common/modals/action-types';

export const modals = (
  state: ReadonlyArray<React.ReactChild> = [],
  action: AnyAction
) => {
  switch (action.type) {
    case OPEN_MODAL:
      return state.concat(action.payload);
    case CLOSE_MODAL:
      return state.slice(0, state.length - 1);
    default:
      return state;
  }
};
