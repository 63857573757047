import 'core-js/es/map';
import 'core-js/es/set';

// tslint:disable-next-line:ordered-imports
import { AppRoot } from '@dabapps/roe';
import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from '^/app';
import { history, store } from '^/store';

ReactDOM.render(
  <AppRoot>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </AppRoot>,
  document.getElementById('app')
);
