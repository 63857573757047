import { AnyAction } from 'redux';

import { FooterBarState } from '^/app/footer-bar/types';
import { CLOSE_FOOTER, OPEN_FOOTER } from './action-types';

export const intialFooterBarState: FooterBarState = {
  msg: null,
  err: null,
  footerBarOpen: false,
};

export const footerBarState = (
  state: FooterBarState = intialFooterBarState,
  action: AnyAction
): FooterBarState => {
  switch (action.type) {
    case OPEN_FOOTER:
      return {
        msg: action.msg,
        err: action.err,
        footerBarOpen: true,
      };
    case CLOSE_FOOTER:
      return {
        msg: null,
        err: null,
        footerBarOpen: false,
      };
    default:
      return state;
  }
};
