import { routerMiddleware } from 'connected-react-router';
import createBrowserHistory from 'history/createBrowserHistory';
import { AnyAction, applyMiddleware, createStore, Middleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import {
  createLocalStorageMiddleware,
  getInitialLocalStorageState,
} from '^/store/middleware/local-storage';
import { StoreState } from '^/store/types';
import authenticationMiddleware from './middleware/authentication';
import createRootReducer from './root-reducer';

export const history = createBrowserHistory();

const localStorageState = getInitialLocalStorageState<StoreState>();

const INITIAL_STATE: Partial<StoreState> = {
  ...localStorageState,
  currentUser: {
    data: undefined,
    error: undefined,
    ...localStorageState.currentUser,
    inFlightCount: 0,
    isLoading: false,
  },
};

const middleware: Array<Middleware<{}, StoreState, any>> = [
  routerMiddleware(history),
  thunk,
  authenticationMiddleware,
  createLocalStorageMiddleware(['currentUser', 'showEstimateStatusColors']),
];

export const store = createStore<StoreState, AnyAction, {}, {}>(
  createRootReducer(history),
  INITIAL_STATE,
  composeWithDevTools(applyMiddleware(...middleware))
);
