import moment from 'moment';
import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';

import { FORM_NAMES } from '^/common/constants';
import { StoreState } from '^/store/types';
import { QuickAddFormData, SearchTask } from './types';

export const getTypeOfDate = (date: Date) => {
  if (moment().isSame(date, 'day')) {
    return 'today';
  }

  if (
    moment()
      .subtract(1, 'day')
      .isSame(date, 'day')
  ) {
    return 'yesterday';
  }

  if (
    moment()
      .subtract(2, 'days')
      .isSame(date, 'day')
  ) {
    return 'two-days-ago';
  }

  return 'custom';
};

export const selectSuggestions = createSelector<
  Pick<StoreState, 'form' | 'recentTasks' | 'searchTasks'>,
  StoreState['recentTasks'],
  StoreState['searchTasks']['cache'],
  QuickAddFormData['search'],
  ReadonlyArray<SearchTask>
>(
  state => state.recentTasks,
  state => state.searchTasks.cache,
  state => formValueSelector(FORM_NAMES.QUICK_ADD)(state, 'search'),
  (recentTasks, cache, search) => {
    if (!search || !(search in cache)) {
      return recentTasks.data || [];
    }

    return cache[search].data || [];
  }
);

export const selectLoadingCurrentSearch = createSelector<
  Pick<StoreState, 'form' | 'searchTasks'>,
  StoreState['searchTasks']['loadingSearches'],
  QuickAddFormData['search'],
  boolean
>(
  state => state.searchTasks.loadingSearches,
  state => formValueSelector(FORM_NAMES.QUICK_ADD)(state, 'search'),
  (loadingSearches, search) =>
    Boolean(search && search in loadingSearches && loadingSearches[search] > 0)
);
