import React from 'react';
import { Field } from 'redux-form';

import { FilterOption } from '^/app/tasks/types';

export interface OwnProps {
  filterOptions: ReadonlyArray<FilterOption>;
  name: string;
  label: string;
  loading: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<any> | undefined, value?: string) => any;
}

export type Props = OwnProps;

export class Filter extends React.PureComponent<Props> {
  public render() {
    const {
      filterOptions,
      name,
      label,
      loading,
      onChange,
      disabled,
    } = this.props;
    return (
      <Field
        name={name}
        id={name}
        component="select"
        disabled={loading || disabled}
        onChange={onChange}
      >
        {!loading && <option value="">All {label}s</option>}
        {loading ? (
          <option>loading...</option>
        ) : (
          filterOptions &&
          filterOptions.map((item: FilterOption) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))
        )}
      </Field>
    );
  }
}
