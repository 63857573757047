import { createSelector } from 'reselect';

import { FilterOption } from '^/app/tasks/types';

export const getClientIdOfProject = createSelector(
  (project: ReadonlyArray<FilterOption>, _id: string | undefined) => project,
  (_project, id) => id,
  (projects, projectId): string | null => {
    const project = projects.find(proj => proj.id === projectId);
    return project && project.client ? project.client.id : null;
  }
);
