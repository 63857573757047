import { ChartDataSets } from 'chart.js';
import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';

import { OwnProps } from '^/app/tasks/task-list-item';
import { FilterOption } from '^/app/tasks/types';
import { Task } from '^/app/tasks/types';
import { FORM_NAMES } from '^/common/constants';
import { GenericObjectType } from '^/common/types';
import { StoreState } from '^/store/types';

export const chartSelector = (state: StoreState) => state.chart;

export const datasetSelector = createSelector(
  chartSelector,
  (chart): ChartDataSets[] => {
    if (!chart) {
      return [];
    }

    const datasets: ChartDataSets[] = [
      {
        borderColor: '#E26262',
        lineTension: 0,
        data: chart.estimate_data.map(parseFloat),
        fill: false,
        spanGaps: true,
      },
      {
        borderColor: '#5f9ea0',
        lineTension: 0,
        data: chart.left_data.map(parseFloat),
        fill: false,
        spanGaps: true,
      },
    ];

    return datasets;
  }
);

export const taskGroupsSelector = (
  state: StoreState
): GenericObjectType<FilterOption> => {
  const { taskGroups } = state;
  const mappedTaskGroups: GenericObjectType<FilterOption> = {};
  if (taskGroups) {
    taskGroups.forEach((task: FilterOption) => {
      mappedTaskGroups[task.id] = task;
    });
  }
  return mappedTaskGroups;
};

const valueSelector = formValueSelector(FORM_NAMES.TASK_FILTERS_FORM_NAME);

export const taskGroupId = (state: StoreState): string | null =>
  valueSelector(state, 'task_group');

export const isTaskGroupBurndownEligible = createSelector(
  taskGroupsSelector,
  taskGroupId,
  (tasksGroupsList, taskGroupID): boolean => {
    return Boolean(
      taskGroupID &&
        tasksGroupsList[taskGroupID] &&
        tasksGroupsList[taskGroupID].is_burn_down_eligible
    );
  }
);

export const tasksSelector = (state: StoreState): GenericObjectType<Task> => {
  const { tasks } = state;
  const mappedTasks: GenericObjectType<Task> = {};
  if (tasks) {
    tasks.results.forEach((task: Task) => {
      mappedTasks[task.id] = task;
    });
  }
  return mappedTasks;
};

export const getTaskId = (_state: StoreState, props: OwnProps): string =>
  props.task.id;

export const isTaskBurndownEligible = createSelector(
  tasksSelector,
  getTaskId,
  (tasks, taskID): boolean =>
    Boolean(tasks[taskID] && tasks[taskID].is_burn_down_eligible)
);
