import { makeAsyncActionSet } from '@dabapps/redux-requests';

export const CLEAR_USER = 'CLEAR_USER';
export const LOGIN = makeAsyncActionSet('LOGIN');
export const LOGOUT = makeAsyncActionSet('LOGOUT');
export const GET_CURRENT_USER = makeAsyncActionSet('GET_CURRENT_USER');
export const RESET_PASSWORD = makeAsyncActionSet('RESET_PASSWORD');
export const RESET_PASSWORD_CONFIRMATION = makeAsyncActionSet(
  'RESET_PASSWORD_CONFIRMATION'
);
