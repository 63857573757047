import { isPending } from '@dabapps/redux-requests';
import { Modal, ModalBody, ModalCloseIcon, ModalHeader } from '@dabapps/roe';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { connect, ResolveThunks } from 'react-redux';

import BanjoChart, { Props as BanjoChartProps } from '^/app/banjo-chart';
import { GET_CHART_DATA, getChartData } from '^/app/banjo-chart/actions';
import { datasetSelector } from '^/app/banjo-chart/selectors';
import Loading from '^/common/loading';
import { closeModal } from '^/common/modals/actions';
import { StoreState } from '^/store/types';

const CHART_LABEL_DATE_FORMAT: string = 'dd Do';

interface OwnProps {
  taskId?: string;
  taskGroupId?: string;
}

interface StateProps extends BanjoChartProps {
  loading: boolean;
}

interface DispatchProps {
  closeModal: typeof closeModal;
  getChartData: typeof getChartData;
}

type Props = OwnProps & ResolveThunks<DispatchProps> & StateProps;

class BurnDownModal extends PureComponent<Props> {
  public constructor(props: Props) {
    super(props);
  }

  public componentDidMount() {
    if (this.props.taskId) {
      this.props.getChartData(this.props.taskId, 'task');
    } else if (this.props.taskGroupId) {
      this.props.getChartData(this.props.taskGroupId, 'task-group');
    }
  }

  public render() {
    return (
      <Modal onClickOutside={this.props.closeModal}>
        <ModalHeader>
          <ModalCloseIcon onClick={this.props.closeModal}>
            <FontAwesomeIcon icon={faTimes} />
          </ModalCloseIcon>
          {this.props.loading ? (
            <Loading />
          ) : (
            <div className="banjo-chart-header-title">
              {this.props.chart?.project_title} -{' '}
              {this.props.chart?.sprint_title} -{' '}
              {this.props.chart?.total_tasks_title} tasks
            </div>
          )}
        </ModalHeader>
        <ModalBody>
          {this.props.loading ? (
            <Loading />
          ) : (
            <BanjoChart
              chart={this.props.chart}
              labels={this.props.labels}
              datasets={this.props.datasets}
            />
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export const mapStateToProps = (state: StoreState): StateProps => {
  return {
    loading: isPending(state.responses, GET_CHART_DATA),
    chart: state.chart,
    labels: state.chart
      ? Array.from(state.chart.labels, date =>
          date ? moment(date).format(CHART_LABEL_DATE_FORMAT) : ''
        )
      : [],
    datasets: datasetSelector(state),
  };
};

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
  mapStateToProps,
  { closeModal, getChartData }
)(BurnDownModal);
