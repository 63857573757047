import { Button, Column, Container, NavBar, Row } from '@dabapps/roe';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { change } from 'redux-form';

import { NAVIGATION_REGEX } from '^/app/navigation/constants';
import NavPrimary from '^/app/navigation/nav-primary';
import NavSecondary from '^/app/navigation/nav-secondary';
import { openSideBar } from '^/app/navigation/side-bar/actions';
import TaskFilters from '^/app/tasks/task-filters';

export type OwnProps = RouteComponentProps<{}>;

export interface DispatchProps {
  openSideBar: typeof openSideBar;
  push: typeof push;
  change: typeof change;
}

export type Props = DispatchProps & OwnProps;

export class NavBarCustom extends React.PureComponent<Props> {
  public render() {
    const { location } = this.props;

    return (
      <NavBar fixed className="padding-none">
        <Container fluid>
          <Row className="nav-row">
            <Column>
              <NavPrimary className="margin-vertical-small float-left" />
              <NavSecondary className="margin-vertical-large display-none md-display-block float-right" />

              <Button
                className="primary md-display-none float-right margin-vertical-base"
                onClick={this.props.openSideBar}
              >
                <Icon icon={faBars} size="lg" />
              </Button>
            </Column>
          </Row>
          {NAVIGATION_REGEX.TASKS_VIEW.test(location.pathname) && (
            <Row className="nav-row sub">
              <Column>
                <TaskFilters />
              </Column>
            </Row>
          )}
        </Container>
      </NavBar>
    );
  }
}

const mapDispatchToProps = {
  openSideBar,
  push,
  change,
};

export default withRouter(connect(undefined, mapDispatchToProps)(NavBarCustom));
