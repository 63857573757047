import { isPending } from '@dabapps/redux-requests';
import { Button, Container } from '@dabapps/roe';
import { faChartLineDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { formValueSelector } from 'redux-form';

import BurnDownModal from '^/app/banjo-chart/modal';
import { isTaskGroupBurndownEligible } from '^/app/banjo-chart/selectors';
import {
  applyFilter,
  GET_TASKS,
  getClients,
  getProjects,
  getTaskGroups,
  getTasks,
} from '^/app/tasks/actions';
import TaskListItem from '^/app/tasks/task-list-item';
import { TaskFiltersObject } from '^/app/tasks/types';
import { getPageFromQueryString } from '^/app/tasks/utils';
import { FORM_NAMES } from '^/common/constants';
import Loading from '^/common/loading';
import { openModal } from '^/common/modals/actions';
import Pagination from '^/common/pagination';
import PaginationDisplay from '^/common/pagination/display';
import SearchForm from '^/common/search/search-form';
import { Complete, SearchData } from '^/common/types';
import { buildCleanFilterOptions, getSearchParamString } from '^/common/utils';
import { StoreState } from '^/store/types';

const PAGE_SIZE = 100;

export interface DispatchProps {
  getClients: typeof getClients;
  getTasks: typeof getTasks;
  getTaskGroups: typeof getTaskGroups;
  applyFilter: typeof applyFilter;
  getProjects: typeof getProjects;
  push: typeof push;
  openModal: typeof openModal;
}

export interface StateProps
  extends Pick<StoreState, 'clients' | 'projects' | 'tasks' | 'taskGroups'> {
  filterOptions: Complete<TaskFiltersObject>;
  currentParams: TaskFiltersObject;
  hasAnyFilterValue: boolean;
  searchValue: string;
  isBurndownAllowed: boolean | undefined;
  tasksIsLoading: boolean;
}

export type Props = StateProps &
  ResolveThunks<DispatchProps> &
  RouteComponentProps<{}>;

export class Tasks extends React.PureComponent<Props> {
  public componentDidMount() {
    const { currentParams } = this.props;
    this.props.getTasks(currentParams);
    this.props.getClients();
    this.props.getProjects(currentParams.client || null);

    if (currentParams.client) {
      this.props.getTaskGroups(
        currentParams.client,
        currentParams.project || null
      );
    }
  }

  public render() {
    const {
      tasks,
      filterOptions,
      hasAnyFilterValue,
      isBurndownAllowed,
      tasksIsLoading,
    } = this.props;

    return (
      <div className="task-view">
        <div className="flex-table fixed-header">
          <div className="flex-table-header">
            <Container fluid>
              <div className="flex-table-row padding-vertical-small">
                <div className="flex-table-column flex-10 md-flex-3 lg-flex-5">
                  <SearchForm
                    form={FORM_NAMES.SEARCH_FORM_NAME}
                    placeholder="Search tasks by name, project or task group"
                    onSubmit={this.searchTasks}
                    searchTasks={this.searchTasks}
                    initialValues={{ query: filterOptions.search }}
                  />
                </div>
                <div className="flex-table-column flex-fill md-flex-1 display-none md-display-flex" />
                <div className="flex-table-column flex-fill md-flex-1 display-none md-display-flex">
                  Status
                </div>
                <div className="flex-table-column flex-fill md-flex-1 display-none md-display-flex">
                  Budget
                </div>
                <div className="flex-table-column flex-fill md-flex-1 display-none md-display-flex">
                  Spent
                </div>
                <div className="flex-table-column flex-fill md-flex-1 display-none md-display-flex">
                  Left
                </div>
                <div className="flex-table-column flex-1">
                  {hasAnyFilterValue && (
                    <Button
                      disabled={!isBurndownAllowed}
                      className="link icon-button"
                      onClick={this.openBurnDownForTaskGroup}
                    >
                      <FontAwesomeIcon
                        className="banjo-burndown-icon"
                        icon={faChartLineDown}
                      />
                    </Button>
                  )}
                </div>
              </div>
            </Container>
          </div>

          <Container fluid className="font-color-light">
            {tasksIsLoading && <Loading />}

            {!tasksIsLoading && tasks && (
              <p>
                Showing <span className="bold">{tasks.results.length}</span>{' '}
                {tasks.results.length === 1 ? 'result' : 'results'}
              </p>
            )}
          </Container>

          {!tasksIsLoading &&
            tasks &&
            tasks.results.map(task => (
              <TaskListItem
                key={task.id}
                task={task}
                openBurnDownForTask={this.openBurnDownForTask}
              />
            ))}
        </div>

        {!tasksIsLoading && tasks && (
          <>
            <Pagination
              className="text-align-center"
              pageSize={PAGE_SIZE}
              itemCount={tasks.count}
              currentPageNumber={filterOptions.page || 1}
              onChangePage={this.onChangePage}
            />
            <PaginationDisplay
              pageSize={PAGE_SIZE}
              itemCount={tasks.count}
              currentPageNumber={filterOptions.page || 1}
            />
          </>
        )}
      </div>
    );
  }

  private updateFiltersAndLoadTasks = (newFilters: TaskFiltersObject) => {
    const filters = buildCleanFilterOptions(newFilters);
    const newParams = queryString.stringify(filters);
    this.props.push({ search: newParams });
    this.props.getTasks(filters);
  };

  private onChangePage = (page: number) => {
    const newFilters = { ...this.props.filterOptions, page };
    this.updateFiltersAndLoadTasks(newFilters);
  };

  private searchTasks = (data: Partial<SearchData>) => {
    const query = data ? data.query : '';
    const newFilters = {
      ...this.props.filterOptions,
      page: undefined,
      search: query,
    };
    this.updateFiltersAndLoadTasks(newFilters);
  };

  private openBurnDownForTask = (taskId: string) => {
    this.props.openModal(<BurnDownModal taskId={taskId} />);
  };

  private openBurnDownForTaskGroup = () => {
    this.props.openModal(
      <BurnDownModal taskGroupId={this.props.filterOptions.task_group} />
    );
  };
}

const valueSelector = formValueSelector(FORM_NAMES.TASK_FILTERS_FORM_NAME);

export const mapStateToProps = (
  state: StoreState,
  props: RouteComponentProps<{}>
): StateProps => {
  const query = queryString.parse(props.location.search);

  const filterOptions = {
    client: valueSelector(state, 'client') as string | undefined,
    project: valueSelector(state, 'project') as string | undefined,
    task_group: valueSelector(state, 'task_group') as string | undefined,
    assignee: valueSelector(state, 'assignee') as string | undefined,
    is_unactionable: valueSelector(state, 'is_unactionable') as
      | 'True'
      | 'False'
      | undefined,
    page: getPageFromQueryString(props),
    search: getSearchParamString(query.search),
  };

  return {
    tasks: state.tasks,
    clients: state.clients,
    projects: state.projects,
    taskGroups: state.taskGroups,
    tasksIsLoading: isPending(state.responses, GET_TASKS),
    filterOptions,
    currentParams: Object.keys(query).length
      ? (query as TaskFiltersObject)
      : {},
    hasAnyFilterValue:
      Boolean(filterOptions.client) ||
      Boolean(filterOptions.project) ||
      Boolean(filterOptions.task_group) ||
      Boolean(filterOptions.assignee) ||
      Boolean(filterOptions.is_unactionable),
    searchValue: formValueSelector(FORM_NAMES.SEARCH_FORM_NAME)(state, 'query'),
    isBurndownAllowed:
      Boolean(filterOptions.task_group) && isTaskGroupBurndownEligible(state),
  };
};

export default connect(mapStateToProps, {
  getTasks,
  getClients,
  getTaskGroups,
  applyFilter,
  getProjects,
  push,
  openModal,
})(Tasks);
