import classNames from 'classnames';
import React from 'react';

export interface Props {
  title: string;
  count: string;
  heavy?: boolean;
}

const ChartHeaderCard: React.SFC<Props> = ({ count, title, heavy }) => (
  <div className={classNames('banjo-chart-header-column', { heavy })}>
    <div className="banjo-chart-header-info banjo-chart-header-info-count">
      {count ? `${count}h` : '--'}
    </div>
    <div className="banjo-chart-header-info banjo-chart-header-info-title">
      {title}
    </div>
  </div>
);

export default ChartHeaderCard;
