import { responsesReducer } from '@dabapps/redux-requests';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import * as authReducers from '^/app/auth/reducers';
import { chart } from '^/app/banjo-chart/reducers';
import { footerBarState } from '^/app/footer-bar/reducers';
import * as sideBarReducers from '^/app/navigation/side-bar/reducers';
import * as tasksReducers from '^/app/tasks/reducers';
import * as quickAddReducers from '^/app/time-entries/quick-add/reducers';
import * as timeEntriesReducers from '^/app/time-entries/reducers';
import * as modalsReducers from '^/common/modals/reducers';
import { StoreState } from './types';

const createRootReducer = (history: History) =>
  combineReducers<StoreState>({
    ...authReducers,
    ...timeEntriesReducers,
    ...modalsReducers,
    ...sideBarReducers,
    ...tasksReducers,
    ...quickAddReducers,
    router: connectRouter(history),
    footerBarState,
    chart,
    form: formReducer,
    responses: responsesReducer,
  });

export default createRootReducer;
