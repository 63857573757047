import { AxiosError } from 'axios';
import moment from 'moment';
import { MouseEvent, TouchEvent } from 'react';
import { AnyAction } from 'redux';
import { submit } from 'redux-form';

import { TaskFiltersObject } from '^/app/tasks/types';
import { DATE_FORMAT_ISO } from './constants';

export const MATCHES_MORE_THAN_2_DECIMALS = /\.\d{3}/;

export const ESCAPE_KEY_CODE: number = 27;
export const SATURDAY: number = 6;
export const SUNDAY: number = 7;

export function hasPayload(action: AnyAction) {
  return action.hasOwnProperty('payload') && action.payload !== undefined;
}

export function hasMeta(action: AnyAction) {
  return action.hasOwnProperty('meta');
}

export function isAxiosError(payload: unknown): payload is AxiosError {
  return Boolean(
    typeof payload === 'object' &&
      payload &&
      'config' in payload &&
      'response' in payload &&
      'name' in payload
  );
}

export function hasPayloadAndIsAxiosError(action: AnyAction) {
  return hasPayload(action) && isAxiosError(action.payload);
}

export function buildCleanFilterOptions(
  data: TaskFiltersObject
): TaskFiltersObject {
  return {
    ...(data.client && { client: data.client }),
    ...(data.project && { project: data.project }),
    ...(data.task_group && { task_group: data.task_group }),
    ...(data.assignee && { assignee: data.assignee }),
    ...(data.is_unactionable && { is_unactionable: data.is_unactionable }),
    ...(data.page && { page: data.page }),
    ...(data.search && { search: data.search }),
  };
}

export const ENTER_KEY_CODE = 13;
export type KeyDownHandler<T> = (event: React.KeyboardEvent<T>) => void;
export function createCallbackOnOnCMDEnterHanlder<T>(
  callback: () => void
): KeyDownHandler<T> {
  return (event: React.KeyboardEvent<T>) => {
    const cmdIsPressed = event.metaKey;

    if (event.keyCode === ENTER_KEY_CODE && cmdIsPressed) {
      event.preventDefault();
      callback();
    }
  };
}

export function createSubmitOnCMDEnterHandler<T>(
  dispatchedFormSubmit: typeof submit,
  formName: string
): KeyDownHandler<T> {
  return createCallbackOnOnCMDEnterHanlder(() =>
    dispatchedFormSubmit(formName)
  );
}

export function isWeekend(dateString: string) {
  const weekday = moment(dateString).isoWeekday();
  return weekday === SATURDAY || weekday === SUNDAY;
}

export const getSearchParamString = (
  search: string | string[] | null | undefined
): string => (Array.isArray(search) ? search.join('') : search || '');

export const preventDefault = (event: MouseEvent | TouchEvent) =>
  event.preventDefault();

export const focusRef = (element: HTMLElement | null) =>
  element && element.focus();

export function toISODate(
  date: Date | string | null | undefined,
  defaultToToday: true
): string;
export function toISODate(
  date: Date | string | null | undefined,
  defaultToToday?: false
): string | null;
export function toISODate(
  date: Date | string | null | undefined,
  defaultToToday?: boolean
): string | null {
  if (typeof date === 'undefined' || date === null || !moment(date).isValid()) {
    return defaultToToday ? moment().format(DATE_FORMAT_ISO) : null;
  }

  return moment(date).format(DATE_FORMAT_ISO);
}
