import { createReducer } from '@dabapps/redux-create-reducer';
import { AxiosResponse } from 'axios';
import { combineReducers } from 'redux';

import { createSimpleRequestReducer } from '^/common/create-simple-request-reducer';
import { ReduxRequestsAction } from '^/common/types';
import {
  CLOSE_AUTO_COMPLETE,
  GET_RECENT_TASKS,
  OPEN_AUTO_COMPLETE,
  SEARCH_TASKS,
  SET_AUTO_COMPLETE_HIGHLIGHTED_INDEX,
} from './actions';
import { SearchTask, SearchTasksState } from './types';

export type SearchAction = ReduxRequestsAction<
  AxiosResponse<{ results: ReadonlyArray<SearchTask> }>,
  { time: number; search: string }
>;

interface SetAutoCompleteHighlightedIndexAction {
  type: string;
  payload: number;
}

export const recentTasks = createSimpleRequestReducer<
  ReadonlyArray<SearchTask>,
  { results: ReadonlyArray<SearchTask> }
>(GET_RECENT_TASKS, {}, response => response.data.results);

export const searchTasks = createReducer<SearchTasksState, SearchAction>(
  {
    [SEARCH_TASKS.REQUEST]: (state, action) => {
      const { search, time } = action.meta;

      return {
        loadingSearches: {
          ...state.loadingSearches,
          [search]: (state.loadingSearches[search] || 0) + 1,
        },
        cache: {
          ...state.cache,
          [search]: {
            ...state.cache[search],
            time,
          },
        },
      };
    },
    [SEARCH_TASKS.SUCCESS]: (state, action) => {
      const { search, time } = action.meta;
      const { results } = action.payload.data;

      if (!(search in state.cache) || time >= state.cache[search].time) {
        return {
          loadingSearches: {
            ...state.loadingSearches,
            [search]: Math.max((state.loadingSearches[search] || 0) - 1, 0),
          },
          cache: {
            ...state.cache,
            [search]: {
              time,
              data: results,
            },
          },
        };
      }

      return {
        loadingSearches: {
          ...state.loadingSearches,
          [search]: Math.max((state.loadingSearches[search] || 0) - 1, 0),
        },
        cache: state.cache,
      };
    },
    [SEARCH_TASKS.FAILURE]: (state, action) => {
      const { search } = action.meta;

      return {
        loadingSearches: {
          ...state.loadingSearches,
          [search]: Math.max((state.loadingSearches[search] || 0) - 1, 0),
        },
        cache: {
          ...state.cache,
          [search]: {
            ...state.cache[search],
            time: 0,
          },
        },
      };
    },
  },
  { loadingSearches: {}, cache: {} }
);

const autoCompleteOpen = createReducer(
  {
    [OPEN_AUTO_COMPLETE]: () => true,
    [CLOSE_AUTO_COMPLETE]: () => false,
  },
  false
);

const autoCompleteHighlightedIndex = createReducer<
  number,
  SetAutoCompleteHighlightedIndexAction
>(
  {
    [SET_AUTO_COMPLETE_HIGHLIGHTED_INDEX]: (_state, action) => {
      return action.payload;
    },
  },
  -1
);

export const autoComplete = combineReducers({
  open: autoCompleteOpen,
  highlightedIndex: autoCompleteHighlightedIndex,
});
