import { SpacedGroup } from '@dabapps/roe';
import React, { MouseEvent } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { logout } from '^/app/auth/actions';
import { User } from '^/app/auth/types';
import { closeSideBar } from '^/app/navigation/side-bar/actions';
import { StoreState } from '^/store/types';

interface OwnProps {
  className?: string;
}

export interface StateProps {
  currentUser: User | undefined;
}

interface DispatchProps {
  onLogout: (event: MouseEvent<HTMLAnchorElement>) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const NavSecondary = ({ currentUser, onLogout, className }: Props) => (
  <SpacedGroup className={className}>
    <p className="md-display-inline">
      Logged in as{' '}
      {currentUser && currentUser.full_name
        ? `${currentUser.full_name}`
        : 'Unknown Person'}
    </p>

    <a className="underline" onClick={onLogout}>
      Logout
    </a>
  </SpacedGroup>
);

export const mapStateToProps = ({
  currentUser,
}: Pick<StoreState, 'currentUser'>): StateProps => {
  return {
    currentUser: currentUser.data,
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction, StoreState>
): DispatchProps => ({
  onLogout: (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    dispatch(closeSideBar());
    dispatch(logout());
  },
});

export { NavSecondary };

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
  mapStateToProps,
  mapDispatchToProps
)(NavSecondary);
