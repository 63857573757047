import { AnyAction, Dispatch } from 'redux';

import { StoreState } from '^/store/types';
import { CLOSE_FOOTER, OPEN_FOOTER } from './action-types';

export const openFooter = (
  status: string | null,
  error: ReadonlyArray<string> | null
) => ({
  type: OPEN_FOOTER,
  msg: status,
  err: error,
  footerBarOpen: true,
});

export const closeFooter = () => ({
  type: CLOSE_FOOTER,
  footerBarOpen: false,
});

export const closeFooterBar = () => {
  return (dispatch: Dispatch<AnyAction, StoreState>) => {
    dispatch(closeFooter());
  };
};
