import { SideBar } from '@dabapps/roe';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import NavSecondary from '^/app/navigation/nav-secondary';
import { closeSideBar } from '^/app/navigation/side-bar/actions';
import { StoreState } from '^/store/types';

export type RouteProps = RouteComponentProps<{}>;

export interface DispatchProps {
  closeSideBar: typeof closeSideBar;
}

export type StateProps = Pick<StoreState, 'sideBarOpen'>;

export type Props = RouteProps & StateProps & DispatchProps;

export class SideBarCustom extends React.PureComponent<Props> {
  public render() {
    return (
      <SideBar
        className="md-display-none"
        open={this.props.sideBarOpen}
        position="right"
        onClickOutside={this.props.closeSideBar}
      >
        <NavSecondary />
      </SideBar>
    );
  }
}

export const mapStateToProps = ({ sideBarOpen }: StoreState): StateProps => {
  return {
    sideBarOpen,
  };
};

export default withRouter(
  connect<StateProps, DispatchProps, RouteProps, StoreState>(mapStateToProps, {
    closeSideBar,
  })(SideBarCustom)
);
