import { AnyAction } from 'redux';

import { GET_CHART_DATA } from './actions';
import { ChartState } from './types';

export const chart = (
  state: ChartState | null = null,
  action: AnyAction
): ChartState | null => {
  switch (action.type) {
    case GET_CHART_DATA.SUCCESS:
      return action.payload.data;

    default:
      return state;
  }
};
