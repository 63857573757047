import { createSimpleRequestReducer } from '^/common/create-simple-request-reducer';
import {
  CLEAR_USER,
  GET_CURRENT_USER,
  RESET_PASSWORD,
  RESET_PASSWORD_CONFIRMATION,
} from './action-types';
import { User } from './types';

export const currentUser = createSimpleRequestReducer<User>(
  GET_CURRENT_USER,
  {},
  undefined,
  {
    [CLEAR_USER]: state => ({
      ...state,
      data: undefined,
    }),
  }
);
export const resetPasswordState = createSimpleRequestReducer<{}>(
  RESET_PASSWORD,
  {}
);
export const resetPasswordConfirmState = createSimpleRequestReducer<{}>(
  RESET_PASSWORD_CONFIRMATION,
  {}
);
