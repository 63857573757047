import { isPending } from '@dabapps/redux-requests';
import {
  Alert,
  Button,
  Column,
  FormGroup,
  ModalBody,
  ModalFooter,
  Row,
  SpacedGroup,
} from '@dabapps/roe';
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
  submit,
} from 'redux-form';

import { updateSelectedDate } from '^/app/tasks/actions';
import { NO_VALUE } from '^/app/tasks/constants';
import { SpentLeftFormData, Task } from '^/app/tasks/types';
import { FORM_NAMES } from '^/common/constants';
import ReduxDatePicker from '^/common/redux-date-picker';
import {
  createSubmitOnCMDEnterHandler,
  KeyDownHandler,
  MATCHES_MORE_THAN_2_DECIMALS,
} from '^/common/utils';
import { StoreState } from '^/store/types';
import { UPDATE_TIMES } from './action-types';
export const MAX_HOURS_WARNING_AMOUNT = 7.5;

interface ExternalProps {
  task: Task;
  onCancel(): void;
}

interface StateProps {
  timeSpent?: string;
  timeLeft?: string;
  isUpdating: boolean;
  isModalClosed: boolean;
}

interface DispatchProps {
  submit: typeof submit;
  updateSelectedDate: typeof updateSelectedDate;
}

export type Props = ExternalProps &
  StateProps &
  DispatchProps &
  InjectedFormProps<
    SpentLeftFormData,
    ExternalProps & StateProps & DispatchProps
  >;

export class SpentLeftForm extends React.PureComponent<Props> {
  private submitOnCMDEnter: KeyDownHandler<HTMLFormElement>;

  public constructor(props: Props) {
    super(props);
    this.submitOnCMDEnter = createSubmitOnCMDEnterHandler(
      this.props.submit,
      FORM_NAMES.SPENT_LEFT
    );
  }

  public render() {
    const { task, timeSpent, isUpdating, isModalClosed } = this.props;

    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyDown={this.submitOnCMDEnter}
        className="spent-left-form"
      >
        <ModalBody>
          {task.created_system !== 'BANJO' && (
            <p className="font-size-small margin-vertical-large">
              <strong>BUDGET</strong>:{' '}
              {this.props.task.time_estimate === null
                ? NO_VALUE
                : this.props.task.time_estimate.toFixed(2)}{' '}
              (h)
            </p>
          )}

          <Row
            className={classnames({
              'grey-border-top': task.created_system !== 'BANJO',
            })}
          >
            <Column md={4}>
              <FormGroup>
                <label>
                  <strong>DATE</strong>
                </label>
                <Field
                  component={ReduxDatePicker}
                  name="date"
                  className="date-input"
                  onChange={this.storeDateValue}
                />
              </FormGroup>
            </Column>
            <Column md={4}>
              <FormGroup block>
                <label>
                  <strong>TIME SPENT</strong>
                </label>

                <Field
                  name="time_spent"
                  type="number"
                  placeholder="0.0 (hours)"
                  min="0"
                  step="0.25"
                  component="input"
                  className="time-input-number"
                  autoFocus
                />

                <span className="font-size-small italic info">
                  Total {task.time_spent === null ? NO_VALUE : task.time_spent}
                </span>
              </FormGroup>
            </Column>
            <Column md={4}>
              {task.is_external && (
                <FormGroup block>
                  <label>
                    <strong>TIME LEFT</strong>
                  </label>
                  <Field
                    name="time_left"
                    type="number"
                    placeholder="0.0 (hours)"
                    min="0"
                    step="0.25"
                    component="input"
                    className="time-input-number"
                  />
                  <span className="font-size-small italic info">
                    Previously{' '}
                    {task.time_left !== null
                      ? task.time_left
                      : task.time_estimate !== null
                      ? task.time_estimate
                      : NO_VALUE}
                  </span>
                </FormGroup>
              )}
            </Column>
          </Row>

          <SpacedGroup className="times">
            {timeSpent && parseFloat(timeSpent) > MAX_HOURS_WARNING_AMOUNT && (
              <Alert className="warning">
                <p>
                  You are trying to log {timeSpent} hours. Are you sure you
                  didn't want to enter minutes instead?
                </p>
              </Alert>
            )}
          </SpacedGroup>

          <Row>
            <Column>
              <FormGroup block>
                <label>
                  <strong>COMMENT</strong>
                </label>
                <Field
                  type="text"
                  component="textarea"
                  name="comment"
                  placeholder="Write a comment to help you remember more details"
                />
              </FormGroup>
            </Column>
          </Row>
        </ModalBody>

        <ModalFooter>
          <SpacedGroup block className="margin-vertical-large">
            <Button
              type="button"
              className="link"
              onClick={this.props.onCancel}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              disabled={!this.hasValidTimes() || isUpdating || isModalClosed}
            >
              Update
            </Button>
          </SpacedGroup>
        </ModalFooter>
      </form>
    );
  }

  private hasValidTimes = () => {
    const { task, timeSpent, timeLeft } = this.props;

    const spent = timeSpent ? parseFloat(timeSpent) : null;
    const left = timeLeft ? parseFloat(timeLeft) : null;

    const externalTaskValidators =
      left !== null &&
      Number.isFinite(left) &&
      left >= 0 &&
      !MATCHES_MORE_THAN_2_DECIMALS.test(left.toString());

    return (
      spent !== null &&
      Number.isFinite(spent) &&
      spent >= 0 &&
      !MATCHES_MORE_THAN_2_DECIMALS.test(spent.toString()) &&
      (!task.is_external || externalTaskValidators)
    );
  };

  private storeDateValue = (
    _: React.ChangeEvent<void> | undefined,
    date: Date
  ) => {
    this.props.updateSelectedDate(date);
  };
}

export const FormifiedSpentLeftForm = reduxForm<
  SpentLeftFormData,
  ExternalProps & StateProps & DispatchProps
>({
  form: FORM_NAMES.SPENT_LEFT,
  destroyOnUnmount: true,
})(SpentLeftForm);

const selectFormValue = formValueSelector(FORM_NAMES.SPENT_LEFT);

export function mapStateToProps(state: StoreState) {
  const date = state.selectedDate || moment.utc().toDate();
  return {
    timeSpent: selectFormValue(state, 'time_spent'),
    timeLeft: selectFormValue(state, 'time_left'),
    initialValues: {
      date,
    },
    isUpdating: isPending(state.responses, UPDATE_TIMES),
    isModalClosed: state.modals && state.modals.length === 0,
  };
}

export default connect(mapStateToProps, { submit, updateSelectedDate })(
  FormifiedSpentLeftForm
);
