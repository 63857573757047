import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { ThunkAction } from 'redux-thunk';

const parseJson = (input: null | undefined | string) => {
  if (!input) {
    return {};
  }

  let json: null | undefined | {};

  try {
    json = JSON.parse(input);
  } catch (error) {
    console.error(error); // tslint:disable-line:no-console
  }

  return json || {};
};

export const createLocalStorageMiddleware = <S>(paths?: Array<keyof S>) => (
  store: MiddlewareAPI<S>
) => (next: Dispatch<AnyAction, S>) => (
  action: AnyAction | ThunkAction<any, S, any>
) => {
  const nextAction = next(action as any);

  const state = store.getState();

  if (typeof state === 'object') {
    let toBeStored: Partial<S> = {};

    if (typeof paths === 'undefined' || !paths.length) {
      toBeStored = state;
    } else {
      for (const key in state) {
        if (paths.indexOf(key as keyof S) >= 0) {
          toBeStored[key as keyof S] = state[key as keyof S];
        }
      }
    }

    window.localStorage.setItem('redux', JSON.stringify(toBeStored));
  }

  return nextAction;
};

export const getInitialLocalStorageState = <S>(): Partial<S> => {
  return parseJson(window.localStorage.getItem('redux'));
};
