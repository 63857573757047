import { Nav, NavItem } from '@dabapps/roe';
import {
  faAnalytics,
  faClock,
  faTasks,
  faTools,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { createSelector } from 'reselect';

import { StoreState } from '^/store/types';
import { NAVIGATION_REGEX } from './constants';
import { NavItemProps } from './types';

interface OwnProps {
  className?: string;
}

type RouteProps = RouteComponentProps<{}>;

interface StateProps {
  links: ReadonlyArray<NavItemProps>;
}

type Props = StateProps & RouteProps & OwnProps;

export const LINKS: ReadonlyArray<NavItemProps> = [
  {
    text: 'Time',
    to: '/time-entries/',
    match: NAVIGATION_REGEX.TIME_ENTRIES,
    icon: faClock,
  },
  {
    text: 'Tasks',
    to: '/',
    match: NAVIGATION_REGEX.TASKS_VIEW,
    icon: faTasks,
  },
  {
    text: 'Reporting',
    to: '/explorer/',
    match: NAVIGATION_REGEX.EXPLORE,
    icon: faAnalytics,
    realLink: true,
    staffOnly: true,
  },
  {
    text: 'Admin',
    to: '/admin/',
    match: NAVIGATION_REGEX.ADMIN,
    icon: faTools,
    realLink: true,
    staffOnly: true,
  },
];

const NavPrimary = ({ links, location, className }: Props) => (
  <Nav className={className}>
    {links.map(link => {
      const linkContent = (
        <>
          <FontAwesomeIcon icon={link.icon} className="fw" />
          <span className="margin-left-small display-none md-display-inline">
            {link.text}
          </span>
        </>
      );

      return (
        <NavItem key={link.to} active={link.match.test(location.pathname)}>
          {link.realLink ? (
            <a
              href={!link.disabled ? link.to : undefined}
              target="_blank"
              className={classNames({
                disabled: link.disabled,
              })}
            >
              {linkContent}
            </a>
          ) : (
            <Link
              className={classNames({
                disabled: link.disabled,
              })}
              to={
                !link.disabled
                  ? {
                      pathname: link.to,
                      search: location.search,
                    }
                  : ''
              }
            >
              {linkContent}
            </Link>
          )}
        </NavItem>
      );
    })}
  </Nav>
);

const selectLinks = createSelector(
  ({ currentUser }: Pick<StoreState, 'currentUser'>) => currentUser.data,
  currentUser => {
    return LINKS.filter(link => !link.staffOnly || currentUser?.is_staff);
  }
);

export const mapStateToProps = (
  state: Pick<StoreState, 'currentUser'>
): StateProps => {
  return {
    links: selectLinks(state),
  };
};

export { NavPrimary };

export default withRouter(connect(mapStateToProps)(NavPrimary));
