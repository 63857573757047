import { Button, Column, FormGroup, Row, Tab, Tabs } from '@dabapps/roe';
import { faCalendar, faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { KeyboardEvent } from 'react';
import { connect } from 'react-redux';
import {
  change,
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import {
  DATE_FORMAT_QUICK_ADD_DISPLAY,
  FORM_NAMES,
  NBSP,
} from '^/common/constants';
import { minZero, required } from '^/common/field-validation';
import { Key, matchesKey } from '^/common/keys';
import DatePicker from '^/common/redux-date-picker';
import { StoreState } from '^/store/types';
import {
  QUICK_ADD_TIME_SPENT_ID,
  quickAddTimeEntry,
  setDateCustom,
  setDateToday,
  setDateTwoDaysAgo,
  setDateYesterday,
} from './actions';
import AutoComplete from './auto-complete';
import { QuickAddFormData } from './types';
import { getTypeOfDate } from './utils';

interface StateProps {
  date: Exclude<QuickAddFormData['date'], undefined>;
  selectedTask: QuickAddFormData['selectedTask'];
  allowSubmission: boolean;
}

interface DispatchProps {
  setDateToday: typeof setDateToday;
  setDateYesterday: typeof setDateYesterday;
  setDateTwoDaysAgo: typeof setDateTwoDaysAgo;
  setDateCustom: typeof setDateCustom;
  changeFormValue: typeof change;
}

interface OuterDispatchProps {
  onSubmit: typeof quickAddTimeEntry;
}

type FormProps = InjectedFormProps<QuickAddFormData, OuterDispatchProps>;

export interface Props
  extends StateProps,
    DispatchProps,
    FormProps,
    OuterDispatchProps {
  clearSelectedTask: () => void;
  timeSpentKeyDown: (event: KeyboardEvent<HTMLElement>) => void;
}

const QuickAdd = (props: Props) => {
  const { selectedTask } = props;
  const twoDaysAgo = moment().subtract(2, 'days');
  const activeTab = getTypeOfDate(props.date);
  const timeLeftNotApplicableMessage =
    selectedTask && !selectedTask.is_external ? '(n/a)' : null;
  const timeLeftMessage =
    timeLeftNotApplicableMessage ||
    (typeof selectedTask?.time_left === 'number'
      ? `(was ${selectedTask?.time_left})`
      : null);
  const timeLeftValidation = selectedTask?.is_external
    ? [required, minZero]
    : undefined;

  return (
    <form
      onSubmit={props.handleSubmit}
      className="quick-add container-fluid margin-vertical-large"
    >
      <Row>
        <Tabs>
          <Tab
            active={activeTab === 'two-days-ago'}
            onClick={props.setDateTwoDaysAgo}
          >
            {twoDaysAgo.format(DATE_FORMAT_QUICK_ADD_DISPLAY)}
          </Tab>
          <Tab
            active={activeTab === 'yesterday'}
            onClick={props.setDateYesterday}
          >
            Yesterday
          </Tab>
          <Tab active={activeTab === 'today'} onClick={props.setDateToday}>
            Today
          </Tab>
          <Tab
            component="label"
            active={activeTab === 'custom'}
            className="calendar"
          >
            {activeTab === 'custom'
              ? moment(props.date).format(DATE_FORMAT_QUICK_ADD_DISPLAY)
              : 'Calendar'}
            <FontAwesomeIcon className="margin-left-base" icon={faCalendar} />
            <Field component={DatePicker} name="date" />
          </Tab>
        </Tabs>
      </Row>
      <Row className="inputs">
        <Column>
          <FormGroup block className="auto-complete-group">
            <label className="text-transform-uppercase bold" htmlFor="search">
              What did you work on?
            </label>
            {selectedTask ? (
              <p className="selected-task" onClick={props.clearSelectedTask}>
                <span className="task-info">
                  <span className="display-block truncate primary">
                    {selectedTask.name}
                  </span>
                  <span className="display-block truncate">
                    {selectedTask.task_group_name}
                  </span>
                </span>
                <FontAwesomeIcon icon={faEdit} className="edit-icon" />
              </p>
            ) : (
              <Field
                component={AutoComplete}
                name="search"
                placeholder="Start typing..."
              />
            )}
          </FormGroup>
          <FormGroup block className="time">
            <label
              className="text-transform-uppercase bold"
              htmlFor="time_spent"
            >
              Spent
              {props.selectedTask && ` (Was ${props.selectedTask.time_spent})`}
            </label>
            <Field
              component="input"
              type="number"
              min={0}
              step={0.25}
              required
              validate={[required, minZero]}
              name="time_spent"
              placeholder="0.0h"
              id={QUICK_ADD_TIME_SPENT_ID}
              onKeyDown={props.timeSpentKeyDown}
            />
          </FormGroup>
          <FormGroup block className="time">
            <label
              className="text-transform-uppercase bold"
              htmlFor="time_left"
            >
              Left {timeLeftMessage}
            </label>
            <Field
              component="input"
              type="number"
              min={0}
              step={0.25}
              required
              validate={timeLeftValidation}
              name="time_left"
              placeholder="0.0h"
              disabled={selectedTask && !selectedTask.is_external}
            />
          </FormGroup>
          <FormGroup block>
            <label className="text-transform-uppercase bold" htmlFor="comment">
              Comment
            </label>
            <Field
              component="input"
              type="text"
              name="comment"
              placeholder="Details are good!"
            />
          </FormGroup>
          <FormGroup block>
            <label className="text-transform-uppercase bold">{NBSP}</label>
            <Button
              className="primary"
              type="submit"
              disabled={!props.allowSubmission}
            >
              Log it
            </Button>
          </FormGroup>
        </Column>
      </Row>
    </form>
  );
};

const valueSelector = formValueSelector<Pick<StoreState, 'form'>>(
  FORM_NAMES.QUICK_ADD
);

export const mapStateToProps = (
  state: Pick<StoreState, 'form' | 'timeEntries'>,
  ownProps: FormProps
): StateProps => {
  const date: QuickAddFormData['date'] = valueSelector(state, 'date');
  const selectedTask: QuickAddFormData['selectedTask'] = valueSelector(
    state,
    'selectedTask'
  );

  return {
    date: date || moment().toDate(),
    selectedTask,
    allowSubmission: Boolean(ownProps.dirty && ownProps.valid && selectedTask),
  };
};

const mapDispatchToProps: DispatchProps = {
  setDateToday,
  setDateYesterday,
  setDateTwoDaysAgo,
  setDateCustom,
  changeFormValue: change,
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: FormProps & OuterDispatchProps
): Props => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  clearSelectedTask: () =>
    dispatchProps.changeFormValue(
      FORM_NAMES.QUICK_ADD,
      'selectedTask',
      undefined
    ),
  timeSpentKeyDown: (event: KeyboardEvent<HTMLElement>) => {
    if (
      stateProps.selectedTask &&
      matchesKey(event, Key.TAB) &&
      event.shiftKey
    ) {
      event.preventDefault();
      dispatchProps.changeFormValue(
        FORM_NAMES.QUICK_ADD,
        'selectedTask',
        undefined
      );
    }
  },
});

export { QuickAdd };

const ConnectedQuickAdd = connect<
  StateProps,
  DispatchProps,
  FormProps & OuterDispatchProps,
  Props,
  Pick<StoreState, 'form' | 'timeEntries'>
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(QuickAdd);

const FormQuickAdd = reduxForm<QuickAddFormData, OuterDispatchProps>({
  form: FORM_NAMES.QUICK_ADD,
})(ConnectedQuickAdd);

export default connect(undefined, { onSubmit: quickAddTimeEntry })(
  FormQuickAdd
);
