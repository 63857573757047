import React from 'react';

export interface OwnProps {
  truncatedUsers: ReadonlyArray<string>;
}

export type Props = OwnProps;

export class TruncatedAssigneesModal extends React.PureComponent<Props> {
  public render() {
    const { truncatedUsers } = this.props;

    return (
      <ul className="truncated-assignees-modal position-absolute padding-base">
        {truncatedUsers.map(assignee => (
          <li className="margin-vertical-base">{assignee}</li>
        ))}
      </ul>
    );
  }
}
