import { AxiosResponse } from 'axios';

import { composeReducers } from '^/common/compose-reducers';
import {
  createSimpleRequestReducer,
  SimpleRequestState,
} from '^/common/create-simple-request-reducer';
import { ReduxRequestsAction } from '^/common/types';
import {
  DELETE_TIME_ENTRY,
  FETCH_TIME_ENTRIES_SUMMARY,
  GET_TIME_ENTRIES_FOR_PAGE,
  GET_TIME_ENTRIES_SPENT,
  UPDATE_TIME_ENTRY,
} from './actions';
import { TimeEntriesSummaryData, TimeEntry } from './types';

export type UpdateOrDeleteAction = ReduxRequestsAction<
  AxiosResponse<TimeEntry>,
  { id: string }
>;

interface Paginated<T> {
  results: ReadonlyArray<T>;
}

export type ListAction = ReduxRequestsAction<
  AxiosResponse<Paginated<TimeEntry>>
>;

export interface LoadingIds {
  loadingIds: readonly string[];
}

export type TimeEntriesState = SimpleRequestState<ReadonlyArray<TimeEntry>> &
  LoadingIds;

export type TimeEntriesSummaryState = SimpleRequestState<
  TimeEntriesSummaryData
>;

const appendPages = (state: TimeEntriesState, action: ListAction) => {
  if (!state.data) {
    return state;
  }

  return {
    ...state,
    data: [...state.data, ...action.payload.data.results],
  };
};

const addLoadingId = (
  state: TimeEntriesState,
  action: UpdateOrDeleteAction
) => ({
  ...state,
  loadingIds: [...state.loadingIds, action.meta.id],
});

const removeLoadingId = (
  state: TimeEntriesState,
  action: UpdateOrDeleteAction
) => {
  const index = state.loadingIds.indexOf(action.meta.id);

  if (index < 0) {
    return state;
  }

  const newLoadingIds = [...state.loadingIds];
  newLoadingIds.splice(index, 1);

  return {
    ...state,
    loadingIds: newLoadingIds,
  };
};

const updateTimeEntrySuccess = (
  state: TimeEntriesState,
  action: UpdateOrDeleteAction
) => {
  if (!state.data) {
    return state;
  }
  const entryIndex = state.data.findIndex(
    entry => entry.id === action.payload.data.id
  );

  if (entryIndex < 0) {
    return state;
  }

  return {
    ...state,
    data: state.data.map(entry => {
      if (entry.id === action.payload.data.id) {
        return action.payload.data;
      }

      return entry;
    }),
  };
};

const deleteTimeEntrySuccess = (
  state: TimeEntriesState,
  action: UpdateOrDeleteAction
) => {
  if (!state.data) {
    return state;
  }

  const entryIndex = state.data.findIndex(entry => entry.id === action.meta.id);

  if (entryIndex < 0) {
    return state;
  }

  return {
    ...state,
    data: state.data.filter(entry => entry.id !== action.meta.id),
  };
};
export const timeEntries = createSimpleRequestReducer<
  ReadonlyArray<TimeEntry>,
  Paginated<TimeEntry>,
  LoadingIds
>(
  GET_TIME_ENTRIES_SPENT,
  { loadingIds: [] },
  response => response.data.results,
  {
    [GET_TIME_ENTRIES_FOR_PAGE.SUCCESS]: appendPages,
    [DELETE_TIME_ENTRY.REQUEST]: addLoadingId,
    [UPDATE_TIME_ENTRY.REQUEST]: addLoadingId,
    [UPDATE_TIME_ENTRY.FAILURE]: removeLoadingId,
    [DELETE_TIME_ENTRY.FAILURE]: removeLoadingId,
    [UPDATE_TIME_ENTRY.SUCCESS]: composeReducers<
      TimeEntriesState,
      UpdateOrDeleteAction
    >(updateTimeEntrySuccess, removeLoadingId),
    [DELETE_TIME_ENTRY.SUCCESS]: composeReducers<
      TimeEntriesState,
      UpdateOrDeleteAction
    >(deleteTimeEntrySuccess, removeLoadingId),
  }
);

export const timeEntriesSummary = createSimpleRequestReducer<
  TimeEntriesSummaryData
>(FETCH_TIME_ENTRIES_SUMMARY, {});
