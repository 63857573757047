import { Column } from '@dabapps/roe';
import { AxiosError } from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { StoreState } from '^/store/types';
import { resetPasswordConfirmation } from './actions';
import LoginWrapper from './login-wrapper';
import ResetPasswordConfirmationForm from './reset-password-confirmation-form';
import { ResetPasswordConfirmationData } from './types';

export interface RouteParamProps {
  uid: string;
  token: string;
}

interface ExternalProps {
  match: {
    params: RouteParamProps;
  };
}

interface DispatchProps {
  resetPasswordConfirmation: typeof resetPasswordConfirmation;
}

interface StateProps {
  loading: boolean;
  data: {} | undefined;
  error: AxiosError | undefined;
}

export type Props = ExternalProps & StateProps & DispatchProps;

export class ResetPasswordConfirmation extends React.PureComponent<Props> {
  public render() {
    const { error, data, loading } = this.props;

    return (
      <LoginWrapper>
        <Column className="panel right">
          <h3>Reset Password</h3>
          <ResetPasswordConfirmationForm
            errors={error?.response?.data}
            loading={loading}
            onSubmit={this.resetPasswordConfirmation}
          />
          {data && (
            <div>
              <p>Your password has been reset.</p>
              <div className="margin-vertical-base">
                <Link className="white-link" to="/">
                  Back to login
                </Link>
              </div>
            </div>
          )}
        </Column>
      </LoginWrapper>
    );
  }

  private resetPasswordConfirmation = (data: ResetPasswordConfirmationData) => {
    this.props.resetPasswordConfirmation({
      ...this.props.match.params,
      ...data,
    });
  };
}

export const mapStateToProps = ({
  resetPasswordConfirmState,
}: StoreState): StateProps => {
  return {
    loading: resetPasswordConfirmState.isLoading,
    data: resetPasswordConfirmState.data,
    error: resetPasswordConfirmState.error,
  };
};

export default connect<StateProps, {}, {}, StoreState>(mapStateToProps, {
  resetPasswordConfirmation,
})(ResetPasswordConfirmation);
