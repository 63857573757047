import { Button, Container, SpacedGroup } from '@dabapps/roe';
import classnames from 'classnames';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';

import {
  fetchTimeEntriesSummary,
  getTimeEntriesForPage,
  getTimeEntriesSpent,
} from '^/app/time-entries/actions';
import {
  DATE_FORMAT_TIME_ENTRY_DISPLAY,
  DEFAULT_WEEKLY_AVAILABLE_HOURS,
} from '^/common/constants';
import ToggleEstimate from '^/common/pagination/toggle-estimate';
import { isWeekend } from '^/common/utils';
import { StoreState } from '^/store/types';
import QuickAdd from './quick-add';
import TimeEntryListItem from './time-entry-list-item';
import { GroupedEntryData, TimeEntriesSummaryData } from './types';
import {
  getFormattedTimeEntryDate,
  getTimeEntriesSummary,
  selectGroupedTimeEntries,
} from './utils';

const WARNING_TIME_COUNT = 7;
const FORM_NAME = 'time-entry-form';
const DEFAULT_PAGE = 1;

export interface DispatchProps {
  getTimeEntriesSpent: typeof getTimeEntriesSpent;
  getTimeEntriesForPage: typeof getTimeEntriesForPage;
  fetchTimeEntriesSummary: typeof fetchTimeEntriesSummary;
}

export interface StateProps {
  groupedTimeEntries: ReadonlyArray<GroupedEntryData>;
  timeEntriesSummary: TimeEntriesSummaryData | undefined;
  pageNumber: number;
}

export type Props = StateProps & ResolveThunks<DispatchProps>;

export class TimeEntries extends React.PureComponent<Props> {
  public static currentPageNumber: number = DEFAULT_PAGE;
  constructor(props: Readonly<Props>) {
    super(props);
    this.increasePageNumber = this.increasePageNumber.bind(this);
    this.resetPageNumber = this.resetPageNumber.bind(this);
  }

  public increasePageNumber() {
    TimeEntries.currentPageNumber = this.props.pageNumber + 1;
    this.props.getTimeEntriesForPage(TimeEntries.currentPageNumber);
  }

  public resetPageNumber() {
    TimeEntries.currentPageNumber = 1;
    this.props.getTimeEntriesSpent(TimeEntries.currentPageNumber);
  }

  public componentDidMount() {
    this.props.fetchTimeEntriesSummary();
    this.props.getTimeEntriesSpent(TimeEntries.currentPageNumber);
  }

  public render() {
    const { groupedTimeEntries, timeEntriesSummary } = this.props;

    const timeEntriesSummaryDisplay = timeEntriesSummary && (
      <p className="entry-list-group-header float-right">
        <span>
          {timeEntriesSummary.weekly_total.current} hours this week (
          {this.getPercentageOfAvailableHours(
            timeEntriesSummary.weekly_total.current
          )}
          %)
        </span>
        <span className="light-grey-text margin-left-base">
          {timeEntriesSummary.weekly_total.previous} hours last week (
          {this.getPercentageOfAvailableHours(
            timeEntriesSummary.weekly_total.previous
          )}
          %)
        </span>
      </p>
    );

    return (
      <div className="time-view">
        <QuickAdd />
        {!groupedTimeEntries.length && timeEntriesSummaryDisplay && (
          <Container fluid className="padding-vertical-large">
            {timeEntriesSummaryDisplay}
          </Container>
        )}
        {groupedTimeEntries.map((itemIdList: GroupedEntryData, index) => (
          <React.Fragment key={itemIdList.itemDate}>
            <Container fluid className="padding-vertical-large">
              <p className="entry-list-group-header bold float-left">
                {getFormattedTimeEntryDate(
                  itemIdList.itemDate,
                  DATE_FORMAT_TIME_ENTRY_DISPLAY
                )}{' '}
                -
                <span
                  className={classnames('entry-list-group-header-count', {
                    warning: itemIdList.rawTotalCount < WARNING_TIME_COUNT,
                    weekend: isWeekend(itemIdList.itemDate),
                  })}
                >
                  {' '}
                  {itemIdList.totalTimeCount}
                </span>
              </p>
              {index === 0 && timeEntriesSummaryDisplay}
            </Container>
            <div className="flex-table">
              <div className="flex-table-header xs-display-none md-display-block">
                <Container fluid>
                  <div className="flex-table-row padding-vertical-small">
                    <div className="flex-table-column flex-fill md-flex-1">
                      Task Id
                    </div>
                    <div className="flex-table-column flex-fill md-flex-1 lg-flex-3 xl-flex-5">
                      <SpacedGroup large>
                        <span>Task</span>
                        <span>Client/Project</span>
                        <span>Task Group</span>
                      </SpacedGroup>
                    </div>
                    <div className="flex-table-column flex-fill md-flex-1">
                      Comment
                    </div>
                    <div className="flex-table-column flex-fill md-flex-1">
                      Time
                    </div>
                    <div className="flex-table-column flex-fill md-flex-1">
                      Date
                    </div>
                    <div className="flex-table-column flex-fill md-flex-2" />
                  </div>
                </Container>
              </div>
              <div className="flex-table-body">
                {itemIdList.items.map(entry => (
                  <TimeEntryListItem
                    key={entry.id}
                    form={`${FORM_NAME}-${entry.id}`}
                    entry={entry}
                  />
                ))}
              </div>
            </div>
          </React.Fragment>
        ))}
        <div className="text-align-center margin-vertical-small">
          <span className="spaced-group display-block nowrap">
            <Button className="primary" onClick={this.increasePageNumber}>
              Load more
            </Button>
            <Button className="primary" onClick={this.resetPageNumber}>
              Reset
            </Button>
          </span>
        </div>
        <Container fluid>
          <ToggleEstimate />
        </Container>
      </div>
    );
  }
  private getPercentageOfAvailableHours(hours: number): string {
    return (
      (hours /
        (this.props.timeEntriesSummary?.available_hours ||
          DEFAULT_WEEKLY_AVAILABLE_HOURS)) *
      100
    ).toFixed(0);
  }
}

export const mapStateToProps = (state: StoreState): StateProps => {
  return {
    groupedTimeEntries: selectGroupedTimeEntries(state),
    timeEntriesSummary: getTimeEntriesSummary(state),
    pageNumber: TimeEntries.currentPageNumber,
  };
};

export default connect<StateProps, DispatchProps, Props, StoreState>(
  mapStateToProps,
  {
    getTimeEntriesSpent,
    getTimeEntriesForPage,
    fetchTimeEntriesSummary,
  }
)(TimeEntries);
