interface KeyCode {
  keyCode: number;
  code: string;
}

export enum Key {
  ENTER = 'ENTER',
  ESCAPE = 'ESCAPE',
  DOWN_ARROW = 'DOWN_ARROW',
  UP_ARROW = 'UP_ARROW',
  TAB = 'TAB',
  SPACE = 'SPACE',
}

const KEY_CODES: Record<Key, KeyCode> = {
  [Key.ENTER]: {
    keyCode: 13,
    code: 'Enter',
  },
  [Key.ESCAPE]: {
    keyCode: 27,
    code: 'Escape',
  },
  [Key.DOWN_ARROW]: {
    keyCode: 40,
    code: 'ArrowDown',
  },
  [Key.UP_ARROW]: {
    keyCode: 38,
    code: 'ArrowUp',
  },
  [Key.TAB]: {
    keyCode: 9,
    code: 'Tab',
  },
  [Key.SPACE]: {
    keyCode: 32,
    code: 'Space',
  },
};

type Handlers = Partial<Record<Key, () => void>>;

export const matchesKey = (
  event: KeyboardEvent | React.KeyboardEvent<HTMLElement>,
  key: Key
): boolean => {
  if ('code' in event && event.code === KEY_CODES[key].code) {
    return true;
  }

  return event.keyCode === KEY_CODES[key].keyCode;
};

export const handleKeys = (
  event: KeyboardEvent | React.KeyboardEvent<HTMLElement>,
  handlers: Handlers
) => {
  const keys = Object.keys(handlers) as ReadonlyArray<Key>;

  keys.forEach(key => {
    if (matchesKey(event, key)) {
      const handler = handlers[key];

      if (handler) {
        handler();
      }
    }
  });
};
