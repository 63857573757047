import {
  makeAsyncActionSet,
  request,
  requestWithConfig,
} from '@dabapps/redux-requests';
import { AnyAction, Dispatch } from 'redux';

import { closeFooterBar, openFooter } from '^/app/footer-bar/actions';
import {
  TOGGLE_ESTIMATE_STATUS_COLORS,
  UPDATE_SELECTED_DATE,
  UPDATE_TIMES,
} from '^/app/tasks/action-types';
import { closeModal } from '^/common/modals/actions';
import { toISODate } from '^/common/utils';
import { StoreState } from '^/store/types';
import { GenericObject, TaskFiltersObject, UpdatedTimes } from './types';

export const GET_TASKS = makeAsyncActionSet('GET_TASKS');

export const getTasks = (data?: TaskFiltersObject) =>
  request(GET_TASKS, '/api/tasks/', 'GET', data);

export const addTimeEntry = (id: string, times: UpdatedTimes) => (
  dispatch: Dispatch<AnyAction, StoreState>
) =>
  dispatch(
    requestWithConfig(UPDATE_TIMES, {
      method: 'POST',
      url: `/api/tasks/${id}/add-time-entry/`,
      data: {
        ...times,
        ...(times.date && { date: toISODate(times.date) }),
      },
    })
  )
    .then(() => {
      dispatch(closeModal());
      dispatch(openFooter(null, null));
      setTimeout(() => {
        dispatch(closeFooterBar());
      }, 3000);
    })
    .catch(() => null);

export const GET_CLIENTS = makeAsyncActionSet('GET_CLIENTS');

export const getClients = () => {
  return request(GET_CLIENTS, '/api/clients/', 'GET');
};

export const GET_TASK_GROUPS = makeAsyncActionSet('GET_TASK_GROUPS');

export const getTaskGroups = (
  projectClient: string | null,
  project: string | null
) => {
  return request(GET_TASK_GROUPS, '/api/task-groups/', 'GET', {
    project__client: projectClient,
    project,
  });
};

export const GET_PROJECTS = makeAsyncActionSet('GET_PROJECTS');

export const getProjects = (client: string | null) => {
  return request(GET_PROJECTS, '/api/projects/', 'GET', {
    client,
  });
};

export const GET_ASSIGNEES = makeAsyncActionSet('GET_ASSIGNEES');

export const getAssignees = () => {
  return request(GET_ASSIGNEES, '/api/accounts/users/', 'GET');
};

export const APPLY_FILTER = makeAsyncActionSet('APPLY_FILTER');

export const applyFilter = (data: GenericObject) => {
  return request(GET_TASKS, '/api/tasks/', 'GET', data);
};

export const updateSelectedDate = (date: Date) => ({
  type: UPDATE_SELECTED_DATE,
  payload: date,
});

export const toggleEstimateStatusColors = () => ({
  type: TOGGLE_ESTIMATE_STATUS_COLORS,
});
