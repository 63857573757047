import { isPending } from '@dabapps/redux-requests';
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalCloseIcon,
  ModalFooter,
  ModalHeader,
  SpacedGroup,
} from '@dabapps/roe';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';

import { Task } from '^/app/tasks/types';
import { closeModal } from '^/common/modals/actions';
import KeyPressWrapper from '^/common/modals/modal-key-press-wrapper';
import {
  createCallbackOnOnCMDEnterHanlder,
  KeyDownHandler,
  MATCHES_MORE_THAN_2_DECIMALS,
} from '^/common/utils';
import { StoreState } from '^/store/types';
import { UPDATE_TIMES } from './action-types';
import { addTimeEntry } from './actions';

export interface OwnProps {
  task: Task;
}

export interface StateProps {
  isUpdating: boolean;
  isModalClosed: boolean;
}

export interface DispatchProps {
  closeModal: typeof closeModal;
  addTimeEntry: typeof addTimeEntry;
}

export interface State {
  hadEstimate: boolean;
  timesAreValid: boolean;
  time_estimate: string;
}

export type Props = StateProps & ResolveThunks<DispatchProps> & OwnProps;

export class EstimateModal extends React.PureComponent<Props, State> {
  private submitOnCMDEnter: KeyDownHandler<HTMLFormElement>;

  public constructor(props: Props) {
    super(props);

    this.state = {
      hadEstimate: typeof props.task.time_estimate === 'number',
      timesAreValid: (props.task.time_estimate || 0) > 0,
      time_estimate: (props.task.time_estimate === null
        ? ''
        : props.task.time_estimate
      ).toString(),
    };

    this.submitOnCMDEnter = createCallbackOnOnCMDEnterHanlder(this.onSubmit);
  }

  public render() {
    return (
      <Modal
        className="estimate-modal"
        small
        onClickOutside={this.onClickCloseModal}
      >
        <ModalHeader>
          <ModalCloseIcon onClick={this.onClickCloseModal}>
            <Icon icon={faTimes} />
          </ModalCloseIcon>
          <p>Update budget</p>
        </ModalHeader>

        <form onSubmit={this.onSubmit} onKeyDown={this.submitOnCMDEnter}>
          <ModalBody>
            <SpacedGroup className="times">
              <FormGroup block>
                <label>Budget (hours)</label>
                <input
                  onChange={this.onChangeEstimate}
                  value={this.state.time_estimate}
                  min="0"
                  step="0.25"
                  autoFocus
                  type="number"
                />
              </FormGroup>
            </SpacedGroup>
          </ModalBody>
          <ModalFooter>
            <SpacedGroup block className="margin-vertical-large">
              <Button
                type="button"
                className="link"
                onClick={this.onClickCloseModal}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                disabled={
                  !this.state.timesAreValid ||
                  this.props.isUpdating ||
                  this.props.isModalClosed
                }
                className="primary"
              >
                Update
              </Button>
            </SpacedGroup>
          </ModalFooter>
        </form>
      </Modal>
    );
  }

  private onClickCloseModal = () => {
    this.props.closeModal();
  };

  private onChangeEstimate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    this.setState({
      time_estimate: value,
    });

    this.validateTimes(value);
  };

  private validateTimes(timeEstimate: string) {
    const estimate = parseFloat(timeEstimate);

    this.setState({
      timesAreValid:
        Number.isFinite(estimate) &&
        estimate > 0 &&
        !MATCHES_MORE_THAN_2_DECIMALS.test(estimate.toString()),
    });
  }

  private onSubmit = (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }

    const estimate = parseFloat(this.state.time_estimate);

    const times = {
      estimate:
        estimate !== this.props.task.time_estimate
          ? { value: estimate }
          : undefined,
    };

    if (typeof times.estimate !== 'undefined') {
      this.props.addTimeEntry(this.props.task.id, times);
    } else {
      this.props.closeModal();
    }
  };
}

const mapStateToProps = (state: StoreState) => {
  return {
    isUpdating: isPending(state.responses, UPDATE_TIMES),
    isModalClosed: state.modals && state.modals.length === 0,
  };
};

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
  mapStateToProps,
  {
    closeModal,
    addTimeEntry,
  }
)(KeyPressWrapper<Props>(EstimateModal));
