import {
  Badge,
  Modal,
  ModalCloseIcon,
  ModalHeader,
  SpacedGroup,
} from '@dabapps/roe';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';

import { addTimeEntry } from '^/app/tasks/actions';
import CreatedSystemIcon from '^/app/tasks/created-system-icon';
import SpentLeftForm from '^/app/tasks/spent-left-form';
import { SpentLeftFormData, Task } from '^/app/tasks/types';
import { ESTIMATE_STATUS_MAP } from '^/common/constants';
import { closeModal } from '^/common/modals/actions';
import KeyPressWrapper from '^/common/modals/modal-key-press-wrapper';

export interface ExternalProps {
  task: Task;
}

export interface DispatchProps {
  closeModal: typeof closeModal;
  addTimeEntry: typeof addTimeEntry;
}

export type Props = ExternalProps & ResolveThunks<DispatchProps>;

export class SpentLeftModal extends React.PureComponent<Props> {
  public render() {
    const { task } = this.props;
    const externalId = task.short_external_id || task.external_id;
    const assignees = task.assignee;

    return (
      <Modal
        className="spent-left-modal"
        small
        onClickOutside={this.onClickCloseModal}
      >
        <ModalHeader
          className={
            (task.created_system === 'TRELLO' ? 'trello-header' : undefined) +
            ' modal-header'
          }
        >
          <ModalCloseIcon onClick={this.onClickCloseModal}>
            <Icon icon={faTimes} />
          </ModalCloseIcon>

          <div className="padding-base header-border">
            <div className="md-padding-top-large">
              <SpacedGroup className="nowrap">
                <CreatedSystemIcon createdSystem={task.created_system} />
                {task.created_system === 'BANJO' ? (
                  <span className="bold primary">DabApps</span>
                ) : (
                  <a href={task.url} target="_blank" className="bold primary">
                    {task.created_system === 'DONEDONE'
                      ? `#${externalId}`
                      : externalId}
                  </a>
                )}
              </SpacedGroup>
            </div>
            <div className="md-padding-top-large font-size-medium">
              {task.name}
            </div>
            <SpacedGroup className="grey-text">
              <div className="sm-padding-top-small">
                {task.project_name} &middot;
                {task.task_group_name}
              </div>
              <div>
                {assignees
                  ? assignees.map((assignee, position) =>
                      position === assignees.length - 1
                        ? assignee
                        : `${assignee}, `
                    )
                  : 'Unassigned'}
              </div>
            </SpacedGroup>
            <div className="margin-vertical-large">
              {task.estimate_status !== 'NO_ESTIMATE' && (
                <Badge
                  className={
                    ESTIMATE_STATUS_MAP[task.estimate_status].badgeClassName
                  }
                  title={ESTIMATE_STATUS_MAP[task.estimate_status].description}
                >
                  {ESTIMATE_STATUS_MAP[task.estimate_status].badgeLabel}
                </Badge>
              )}
            </div>
          </div>

          <SpentLeftForm
            onSubmit={this.onSubmit}
            task={task}
            onCancel={this.onClickCloseModal}
          />
        </ModalHeader>
      </Modal>
    );
  }

  public onClickCloseModal = () => {
    this.props.closeModal();
  };

  private onSubmit = (data: Partial<SpentLeftFormData>) => {
    const { date, time_left, time_spent, comment } = data;
    const left = time_left ? parseFloat(time_left) : 0;
    const spent = time_spent ? parseFloat(time_spent) : 0;

    const times = {
      date,
      comment,
      spent: { value: spent },
      left: { value: left },
    };

    this.props.addTimeEntry(this.props.task.id, times);
  };
}

export default connect(null, {
  closeModal,
  addTimeEntry,
})(KeyPressWrapper<Props>(SpentLeftModal));
