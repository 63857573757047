import { push } from 'connected-react-router';
import queryString from 'query-string';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { change } from 'redux-form';

import { getClientIdOfProject } from '^/app/navigation/nav-bar/selectors';
import { getProjects, getTaskGroups, getTasks } from '^/app/tasks/actions';
import TaskFiltersForm from '^/app/tasks/task-filters-form';
import { FilterOption } from '^/app/tasks/types';
import { TaskFiltersObject } from '^/app/tasks/types';
import { FORM_NAMES } from '^/common/constants';
import { buildCleanFilterOptions, getSearchParamString } from '^/common/utils';
import { StoreState } from '^/store/types';

export type ExternalProps = RouteComponentProps<{}>;

export interface DispatchProps {
  getTasks: typeof getTasks;
  push: typeof push;
  change: typeof change;
  getProjects: typeof getProjects;
  getTaskGroups: typeof getTaskGroups;
}

export interface StateProps {
  projects: ReadonlyArray<FilterOption>;
  search?: string;
}

export type Props = ExternalProps & ResolveThunks<DispatchProps> & StateProps;

export class TaskFilters extends React.PureComponent<Props> {
  public render() {
    return <TaskFiltersForm onChange={this.onChangeFilters} {...this.props} />;
  }

  private onChangeClient = (
    newFilters: TaskFiltersObject
  ): TaskFiltersObject => {
    const { client, project, assignee, is_unactionable } = newFilters;
    const clientIdForProject = getClientIdOfProject(
      this.props.projects,
      project
    );

    this.props.getProjects(client || null);
    this.props.getTaskGroups(client || null, project || null);

    const filters = {
      client,
      assignee,
      is_unactionable,
    };

    if (client !== clientIdForProject) {
      this.props.change(FORM_NAMES.TASK_FILTERS_FORM_NAME, 'project', '');
      return filters;
    }

    return {
      ...filters,
      project,
    };
  };

  private onChangeProject = (
    newFilters: TaskFiltersObject
  ): TaskFiltersObject => {
    const { client, project, assignee, is_unactionable } = newFilters;
    const clientIdForProject = getClientIdOfProject(
      this.props.projects,
      project
    );

    this.props.getTaskGroups(client || null, project || null);

    if (project && client !== clientIdForProject) {
      this.props.change(
        FORM_NAMES.TASK_FILTERS_FORM_NAME,
        'client',
        clientIdForProject || ''
      );
    }

    return {
      ...(clientIdForProject && { client: clientIdForProject }),
      project,
      assignee,
      is_unactionable,
    };
  };

  private updateTaskFiltersAndLoadUpdatedOptions = (
    previousFilters: Partial<TaskFiltersObject>,
    newFilters: Partial<TaskFiltersObject>
  ): TaskFiltersObject => {
    if (previousFilters.client !== newFilters.client) {
      return this.onChangeClient(newFilters);
    } else if (previousFilters.project !== newFilters.project) {
      return this.onChangeProject(newFilters);
    }

    return newFilters;
  };

  private onChangeFilters = (
    newFilters: Partial<TaskFiltersObject>,
    _dispatch: void, // NOTE: unused arg has to be declared due to redux form argument order
    _: {}, // NOTE: unused arg has to be declared due to redux form argument order
    previousValues: Partial<TaskFiltersObject>
  ) => {
    const filters = buildCleanFilterOptions({
      ...this.updateTaskFiltersAndLoadUpdatedOptions(
        previousValues,
        newFilters
      ),
      page: undefined,
      search: this.props.search,
    });

    const newParams = queryString.stringify(filters);

    this.props.push({ search: newParams });
    this.props.getTasks(filters);
  };
}

export const mapStateToProps = (
  state: StoreState,
  props: RouteComponentProps<{}>
): StateProps => {
  const search = queryString.parse(props.location.search).search;
  return {
    projects: state.projects || [],
    search: getSearchParamString(search),
  };
};

export default withRouter(
  connect<StateProps, DispatchProps, ExternalProps, StoreState>(
    mapStateToProps,
    {
      getTasks,
      push,
      change,
      getProjects,
      getTaskGroups,
    }
  )(TaskFilters)
);
