import { AxiosError } from 'axios';
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';

import { clearUser } from '^/app/auth/actions';
import { hasPayloadAndIsAxiosError } from '^/common/utils';
import { StoreState } from '^/store/types';

// 403 is included as this is what DRF returns when a session has expired as well as permission denied
export const LOGGED_OUT_STATUSES = [401, 403];

function isUnauthenticated(payload: AxiosError) {
  return payload.response
    ? LOGGED_OUT_STATUSES.indexOf(payload.response.status) !== -1
    : false;
}

function isAuthenticatedInState({
  currentUser,
}: Pick<StoreState, 'currentUser'>): boolean {
  return Boolean(currentUser.data);
}

export function shouldLogout(
  action: AnyAction,
  state: Pick<StoreState, 'currentUser'>
) {
  return (
    hasPayloadAndIsAxiosError(action) &&
    isUnauthenticated(action.payload) &&
    isAuthenticatedInState(state)
  );
}

export default (
  store: MiddlewareAPI<Dispatch<AnyAction, StoreState>, StoreState>
) => (next: Dispatch<AnyAction, StoreState>) => (action: AnyAction) => {
  if (shouldLogout(action, store.getState())) {
    next(clearUser());
    return window.location.replace('/');
  }

  return next(action);
};
