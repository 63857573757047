import { AnyAction } from 'redux';

import { TOGGLE_ESTIMATE_STATUS_COLORS } from '^/app/tasks/action-types';
import { UPDATE_SELECTED_DATE, UPDATE_TIMES } from '^/app/tasks/action-types';
import {
  GET_ASSIGNEES,
  GET_CLIENTS,
  GET_PROJECTS,
  GET_TASK_GROUPS,
  GET_TASKS,
} from '^/app/tasks/actions';
import { PaginatedResponse } from '^/common/types';
import { Assignee, FilterOption, GenericObject, Task } from './types';

export const tasks = (
  state: null | PaginatedResponse<Task> = null,
  action: AnyAction
) => {
  switch (action.type) {
    case GET_TASKS.SUCCESS:
      return action.payload.data;
    case GET_TASKS.REQUEST:
    case GET_TASKS.FAILURE:
      return null;
    case UPDATE_TIMES.SUCCESS:
      if (!state) {
        return state;
      }

      const taskIndex = state.results.findIndex(
        task => task.id === action.payload.data.id
      );

      if (taskIndex < 0) {
        return state;
      }

      return {
        ...state,
        results: state.results.map(task =>
          task.id === action.payload.data.id ? action.payload.data : task
        ),
      };
    default:
      return state;
  }
};

const createListReducer = <T>(actionSet: GenericObject) => (
  state: null | ReadonlyArray<T> = null,
  action: AnyAction
): ReadonlyArray<T> | null => {
  switch (action.type) {
    case actionSet.REQUEST:
    case actionSet.FAILURE:
      return null;
    case actionSet.SUCCESS:
      return action.payload.data.results;

    default:
      return state;
  }
};

export const clients = createListReducer<FilterOption>(GET_CLIENTS);
export const projects = createListReducer<FilterOption>(GET_PROJECTS);
export const taskGroups = createListReducer<FilterOption>(GET_TASK_GROUPS);
export const assignees = createListReducer<Assignee>(GET_ASSIGNEES);

export const selectedDate = (state: null | Date = null, action: AnyAction) => {
  switch (action.type) {
    case UPDATE_SELECTED_DATE:
      return action.payload;
    default:
      return state;
  }
};

export const updateTimesRequest = UPDATE_TIMES;

export const showEstimateStatusColors = (
  state = false,
  action: AnyAction
): boolean => {
  switch (action.type) {
    case TOGGLE_ESTIMATE_STATUS_COLORS:
      return !state;
    default:
      return state;
  }
};
