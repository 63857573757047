import { Badge, Button, Container } from '@dabapps/roe';
import { faChartLineDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import { User } from '^/app/auth/types';
import { isTaskBurndownEligible } from '^/app/banjo-chart/selectors';
import { Task } from '^/app/tasks/types';
import { ESTIMATE_STATUS_MAP } from '^/common/constants';
import { openModal } from '^/common/modals/actions';
import { StoreState } from '^/store/types';
import { NO_VALUE, NO_VALUE_SET } from './constants';
import CreatedSystemIcon from './created-system-icon';
import EstimateModal from './estimate-modal';
import SpentLeftModal from './spent-left-modal';
import { TruncatedAssigneesModal } from './truncated-assignees-modal';

export interface DispatchProps {
  openModal: typeof openModal;
}

export interface OwnProps {
  task: Task;
  openBurnDownForTask: (taskId: string) => void;
}

export interface StateProps {
  currentUser: User | undefined;
  isBurndownAllowed: boolean | undefined;
  showEstimateStatusColors: boolean;
}

export type Props = DispatchProps & OwnProps & StateProps;

interface State {
  isHovering: boolean;
}

export class TaskListItem extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isHovering: false,
    };
  }

  public render() {
    const { task, showEstimateStatusColors } = this.props;
    const badgeLabel = ESTIMATE_STATUS_MAP[task.estimate_status].badgeClassName;
    const truncatedUsers: string[] = [];
    const externalId = task.short_external_id || task.external_id;

    return (
      <div
        className={classNames(
          'flex-table-bottom-border',
          'padding-vertical-large',
          {
            [ESTIMATE_STATUS_MAP[task.estimate_status]
              .className]: showEstimateStatusColors,
          }
        )}
      >
        <Container fluid>
          <div className="flex-table-row">
            <div className="flex-table-column flex-fill md-flex-2 lg-flex-1">
              {task.created_system === 'BANJO' ? (
                <>
                  <CreatedSystemIcon createdSystem={task.created_system} />{' '}
                  <span className="task-id bold">DabApps</span>
                </>
              ) : (
                <a href={task.url} target="_blank" className="nowrap">
                  <CreatedSystemIcon createdSystem={task.created_system} />{' '}
                  <span className="task-id bold">
                    {task.created_system === 'DONEDONE'
                      ? `#${externalId}`
                      : externalId}
                  </span>
                </a>
              )}
            </div>

            <div className="flex-table-column flex-fill md-flex-2 lg-flex-5">
              <div>
                <div className="task-title">{task.name}</div>
                <div className="font-color-light">
                  {task.project_name || 'Unknown Project'} &middot;{' '}
                  {task.task_group_name || 'Unknown Task Group'}
                  {task.assignee ? (
                    <>
                      {task.assignee.length >= 1 && <> &middot; </>}
                      {task.assignee.map((assignee, i) => {
                        if (i >= 2) {
                          truncatedUsers.push(assignee);
                        } else if (task.assignee) {
                          return i === 0 && task.assignee.length > 1
                            ? `${assignee}, `
                            : assignee;
                        }
                      })}
                    </>
                  ) : (
                    <em>Unassigned</em>
                  )}
                  {truncatedUsers.length >= 2 && (
                    <span
                      onMouseEnter={this.onTruncatedUsersHover}
                      onMouseLeave={this.onTruncatedUsersHover}
                      className="margin-left-small display-inline-block"
                    >
                      +{' '}
                      <span className="underline">
                        {truncatedUsers.length} more
                      </span>
                      {this.state.isHovering && (
                        <TruncatedAssigneesModal
                          truncatedUsers={truncatedUsers}
                        />
                      )}
                    </span>
                  )}
                  {badgeLabel && showEstimateStatusColors ? (
                    <>
                      {' '}
                      &middot;{' '}
                      <Badge
                        className={
                          ESTIMATE_STATUS_MAP[task.estimate_status]
                            .badgeClassName
                        }
                        title={
                          ESTIMATE_STATUS_MAP[task.estimate_status].description
                        }
                      >
                        {ESTIMATE_STATUS_MAP[task.estimate_status].badgeLabel}
                      </Badge>
                    </>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="flex-table-column flex-fill md-flex-1">
              {task.task_status}
            </div>

            <div className="flex-table-column flex-fill md-flex-1">
              {this.props.currentUser && task.is_external ? (
                this.props.currentUser.is_staff ? (
                  <button
                    onClick={this.onClickOpenEstimateModal}
                    className={classNames('task-time button hollow pill', {
                      info: this.isTimeEstimated(),
                    })}
                  >
                    {this.isTimeEstimated() ? task.time_estimate : NO_VALUE_SET}
                    {this.isTimeEstimated() && (
                      <span className="task-unit">h</span>
                    )}
                  </button>
                ) : this.isTimeEstimated() ? (
                  <span>{task.time_estimate}h</span>
                ) : (
                  'Not set'
                )
              ) : (
                NO_VALUE
              )}
            </div>

            <div className="flex-table-column flex-fill md-flex-1">
              <button
                onClick={this.onClickOpenSpentLeftModal}
                className={classNames('task-time button hollow pill', {
                  info: task.time_spent !== null && task.time_spent > 0,
                })}
              >
                <span
                  className={classNames(
                    this.getTaskTimeSpentClass(task.time_spent)
                  )}
                >
                  {task.time_spent === null ? NO_VALUE_SET : task.time_spent}
                </span>
                <span className="task-unit">h</span>
              </button>
            </div>

            <div className="flex-table-column flex-fill md-flex-1">
              {task.is_external ? (
                <button
                  onClick={this.onClickOpenSpentLeftModal}
                  className={classNames('task-time button hollow pill', {
                    info: task.time_left !== null && task.time_left > 0,
                  })}
                >
                  {task.time_left === null ? NO_VALUE_SET : task.time_left}
                  {task.time_left !== null && (
                    <span className="task-unit">h</span>
                  )}
                </button>
              ) : (
                NO_VALUE
              )}
            </div>

            <div className="flex-table-column flex-fill md-flex-1">
              <Button
                className="link icon-button"
                disabled={!this.props.isBurndownAllowed}
                onClick={this.onGraphIconClick}
              >
                <FontAwesomeIcon
                  className="banjo-burndown-icon"
                  icon={faChartLineDown}
                />
              </Button>
            </div>
          </div>
        </Container>
      </div>
    );
  }

  private isTimeEstimated = () => {
    const {
      task: { time_estimate },
    } = this.props;
    if (time_estimate === null) {
      return false;
    } else {
      return true;
    }
  };

  private getTaskTimeSpentClass(timeSpent: number | null) {
    if (timeSpent === null) {
      return 'warning';
    } else if (timeSpent === 0) {
      return '';
    } else {
      return 'info';
    }
  }

  private onClickOpenEstimateModal = () => {
    this.props.openModal(<EstimateModal task={this.props.task} />);
  };

  private onClickOpenSpentLeftModal = () => {
    this.props.openModal(<SpentLeftModal task={this.props.task} />);
  };

  private onGraphIconClick = () => {
    this.props.openBurnDownForTask(this.props.task.id);
  };

  private onTruncatedUsersHover = () => {
    this.setState(this.toggleHoverState);
  };

  private toggleHoverState = (state: State) => ({
    isHovering: !state.isHovering,
  });
}

export const mapStateToProps = (
  state: StoreState,
  props: OwnProps
): StateProps => {
  const { showEstimateStatusColors } = state;
  return {
    currentUser: state.currentUser.data,
    showEstimateStatusColors,
    isBurndownAllowed: isTaskBurndownEligible(state, props),
  };
};

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
  mapStateToProps,
  {
    openModal,
  }
)(TaskListItem);
